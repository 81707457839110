import { PORTAL_CONSTANT } from "~/constant/site/portal/portalConstant";
const URL_GET_CERT = "/api/user/v1/portalUserGpkiApi_getGpkiAuthentication";

/**
 * Portal EPKI Common
 *
 * @property {function} settingEpkiLibByUserRole : 유저 타입에 띠라 Epki Load 여부를 결정합니다. 교직원이나 교직원(강원도내)일 경우 epki 라이브러리 정보를 반환합니다.
 * @property {function} getEpkiLib : Epki Library를 로드하기위한 배열을 가져옵니다.
 * @property {function} getCertStr : Epki 인증 절차를 진행합니다.
 * @property {function} getEpkiApiUrlByEnv : 개발환경에 따라 다른 URL 정보를 반환받습니다.
 * */
const portalEpkiCommon = {

  /**
   * 유저 타입에 띠라 Epki Load 여부를 결정합니다.
   * 교직원이나 교직원(강원도내)일 경우 epki 라이브러리 정보를 반환합니다.
   *
   * @param {String} userType - USER_TYPE
   * @param {Object} that     - vue Instant
   * @return @return {Arrau<Object>|[]} - 페이지 head()에 Setting할 정보
   */
  settingEpkiLibByUserRole( userType , that ) {

    // 모바일에서는 GPKI 가 실행되지 않아야 합니다.
    let isMobile = $nuxt.$validate.isMobile();

    // 본인 인증을 거친 유저는 GPKI가 실행되지 않아야 합니다.
    let isSelfCerted = $nuxt.$store.state.common.login.loginUser.isSelfCerted;

    if ( isMobile || isSelfCerted ) {
      return [];
    }
    else if ( PORTAL_CONSTANT.USER_TYPE.STAFF.KEY === userType
      || PORTAL_CONSTANT.USER_TYPE.STAFF_GANGWON.KEY === userType ){

      return this.getEpkiLib( that );
    }
    else {
      return [];
    }
  },

  /**
   * Epki Library를 로드하기위한 배열을 가져옵니다.
   *
   * @param {Object} that     - vue Instant
   * @return {Arrau<Object>} - 페이지 head()에 Setting할 정보
   * */
  getEpkiLib( that ) {

    return [
      {src: "/epkiLib/js/jquery-ui.js", ssr: false, id: "jquery-ui.js"},
      {src: "/epkiLib/js/jquery-1.11.3.js", ssr: false, id: "jquery-1.11.3.js" , callback : () => { that.epkiLoaded = true } },
      {src: "/epkiLib/js/nppfs-1.9.0.js" ,  skip : !that.epkiLoaded, ssr: false, id: "nppfs-1.9.0.js"},
      {src: "/epkiLib/js/kcase_os_check.js",  skip : !that.epkiLoaded , ssr: false, id: "kcase_os_check.js" , callback : () => { that.epkiCommonLoaded = true } },
      {
        src: "/epkiLib/EPKICommon.js", skip : !that.epkiCommonLoaded , ssr: false, id: "EPKICommon.js", callback: () => {
          portalEpkiCommon.getCertStr( that );
        }
      },
    ];
  },

  /**
   * Epki 인증 절차를 진행합니다.
   *
   * @param {Object} that     - vue Instant
   * @return { { sessionId : String, serverCert : String } }
   * */
  async getCertStr( that ){

    let epkiInfo = {};

    await $nuxt.$axios.post( this.getEpkiApiUrlByEnv( URL_GET_CERT ) ).then( res => {

      if ( $nuxt.$validate.isEmpty( res.data ) ){
        return;
      }

      try {

        that.gpkiInfo.sessionId = res.data.sessionId;
        that.gpkiInfo.serverCert = res.data.strServerCert;
      }
      catch ( e ) {
        console.error( "data()에 gpkiInfo를 추가해야 합니다." )
      }

      epki.init( epkiInfo.sessionId );

    });

    return epkiInfo;

  },

  /**
   * 개발환경에 따라 다른 URL 정보를 반환받습니다.
   *
   * @param {String} url - API - URL
   * @return {String} - 로컬 환경이면 개발서버 API를 반환
   * */
  getEpkiApiUrlByEnv( url ) {

    const devServerPrefix = "http://portal.testremarkable.com";
    return ".local" === process.env.NUXT_ENV_STAGE ? devServerPrefix + url : url;
  },
}

export default portalEpkiCommon
