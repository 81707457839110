export default class SearchCnd {

  /**
   * 검색 값 보존 프로퍼티 클래스.
   *
   * @author cheeeeze
   * @최종수정일 2022-03-10
   */
                                          // [ Tag 타입 ] / [ 내용 ]

  statusTypeFlag = "";                    // radio / 사용자 회원 상태 ( 'U', 'W', ... )
  searchTypeOptionsValue = "";            // select / 검색어 선택값 ( 이름, 아이디, neisId... )
  searchQuery = "";                       // input / 검색어
  refDate = "";                           // radio / 날짜 검색 선택타입

  organizationName = "";                  // input / 학교(기관) 검색어

  statusTypeSet = [];
  userTypeSet = [];
  searchDate = [];                        // datepicker 날짜 검색 선택일


	constructor() {}

	setSearchCnd( data ) {

    // 사용자 회원 상태
    if ( data.statusTypeFlag ) {
      this.statusTypeFlag = data.statusTypeFlag;
    }

    if ( $nuxt.$validate.isNotEmpty( data.statusTypeSet ) ) {
      this.statusTypeSet = data.statusTypeSet;
    }

    if ( $nuxt.$validate.isNotEmpty( data.userTypeSet ) ){
      this.userTypeSet = data.userTypeSet;
    }

    // 이름
    if ( data.name ) {
      this.searchQuery = data.name;
      this.searchTypeOptionsValue = "name";
    }
    // 아이디
    if ( data.userId ) {
      this.searchQuery = data.userId;
      this.searchTypeOptionsValue = "userId";
    }
    // NEIS 아이디
    if ( data.neisId ) {
      this.searchQuery = data.neisId;
      this.searchTypeOptionsValue = "neisId";
    }

    // 날짜 검색 선택 타입
    if ( data.refDate ) {
      this.refDate = data.refDate;
    }

    // 가입일 검색
    if ( data.joinDateFrom || data.joinDateTo ) {
      this.searchDate = [ data.joinDateFrom, data.joinDateTo ];
    }

    // 마지막 로그인 검색
    if ( data.loginDateFrom || data.loginDateTo ) {
      this.searchDate = [ data.loginDateFrom, data.loginDateTo ];
    }

    // 학교(기관명) 검색어
    if ( data.organizationName ) {
      this.organizationName = data.organizationName;
    }


	}
}
