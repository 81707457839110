/**
 *
 * Tag 유틸
 *
 * */
const tagUtils = {

  /**
   *
   * 태그의 문자열 리스트를 서버에서 사용할 수 있도록
   * tagList로 컨버팅해준다
   * 
   * @param tags : 태그의 문자열 리스트가 넘어온다
   *
   * @return {Object} inputYnValue
   * */
  convertTagSet( tags ) {

    if ( $nuxt.$validate.isEmpty( tags ) ) {
      return;
    }

    return tags.map( tag => ( { tagName: tag } ) );
  },


}

export default tagUtils;
