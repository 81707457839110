import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
import { PORTAL_CONSTANT } from "~/constant/site/portal/portalConstant";
import portalApiUrl from '~/constant/site/portal/portalApiUrl';

/**
 * 포털 메뉴 유틸
 */
const portalMenuUtils = (() => {

  const { isEmpty } = typeValidationUtils;

  /**
   * 메뉴의 전체 url을 구합니다.
   *
   * @param menu
   * @param url
   * @return {string}
   */
  function getFullUrl( menu, url ) {

    if ( isEmpty( menu ) ) {
      return "";
    }

    if ( isEmpty( menu.childTreeNodeList ) ) {
      return url + menu.url;
    }

    return url + getFullUrl( menu.childTreeNodeList[0], menu.url );
  }

  /**
   * 메뉴 타입별 메뉴 타겟 아이템의 존재여부를 확인합니다.
   * @param menu
   */
  async function existByMenuType( menu ) {

    const { data } = await $nuxt.$axios.post( portalApiUrl.USER.MENU.EXIST_BY_MENU_TYPE, menu );
    return data;
  }

  return {

    /**
     * 로그인 후 관리자 페이지의 최초 화면 URL 세팅
     *
     * @return {string}
     */
    getFirstAdminMenu() {

      let adminMenuList = $nuxt.$store.getters[ "portal/menuRole/adminMenuListByRole" ];

      if ( isEmpty( adminMenuList ) ) {
        return "";
      }

      const firstDepthMenu = adminMenuList[0];

      // iframe 메뉴일 경우 iframe 경로로 이동
      if ( PORTAL_CONSTANT.MENU_TYPE_FLAG.IFRAME.KEY === firstDepthMenu.menuTypeFlag ) {

        return PORTAL_CONSTANT.MANAGER_MAIN_PAGE_URL.ETC + firstDepthMenu.menuOid;
      }

      return getFullUrl( firstDepthMenu, "" );
    },

    /**
     * 메뉴 타입이 게시판, 화면, 혼합형일 경우 해당 페이지로 이동합니다.
     *
     * @param menu
     * @return {Promise<string>}
     */
    async getMenuItemUrl( menu ) {

      if ( isEmpty( menu ) || !await existByMenuType( menu ) ) {
        return "";
      }

      // 게시판
      if ( PORTAL_CONSTANT.MENU_TYPE_FLAG.BOARD.KEY === menu.menuTypeFlag ) {
        return `/portal/user/common/commonBoard_list?menuOid=${menu.menuOid}`;
      }
      // 화면
      else if ( PORTAL_CONSTANT.MENU_TYPE_FLAG.SCREEN.KEY === menu.menuTypeFlag ) {
        return `/portal/user/common/commonScreen?menuOid=${menu.menuOid}`;
      }
      // 혼합형
      else if ( PORTAL_CONSTANT.MENU_TYPE_FLAG.MIX.KEY === menu.menuTypeFlag ) {
        return `/portal/user/common/commonForm?menuOid=${menu.menuOid}&menuTypeFlag=M`;
      }

      return "";
    },

  }
})();

export default portalMenuUtils;