<template>
  <div class="main-search-area">
    <form class="search-form" @submit.prevent>
      <fieldset class="search-box">
        <label class="is-label" for="mainSearch">검색어 입력</label>
        <el-autocomplete
          id="mainSearch"
          v-model="searchInput"
          :class="{'placeholder-black' : prefixBarVisible}"
          popper-class="search-popper"
          title="검색어 입력"
          :fetch-suggestions="querySearch"
          :placeholder="'검색어를 입력하세요'"
          :popper-append-to-body="false"
          @keyup.prevent.enter.native="onClickSearch"
          @focus="onSearchCompleteOpen()"
          @blur="prefixBarVisible = true"
          @select="onSelectWord"
        />
      </fieldset>
      <!-- 버튼 -->
      <el-button type="pale" class="is-transparent shade-bg is-circle size-md"
                 @click="onClickSearch">
        <span class="material-icons-outlined lg">search</span>
      </el-button>
    </form>

    <!-- 최대 갯수 3개 -->
    <div class="search-tag">
      <ul class="tag-list">
        <li>
          <span @click="onClickTag('교육연수')">#교육연수</span>
        </li>
        <li>
          <span @click="onClickTag('체험안내')">#체험안내</span>
        </li>
        <li>
          <span @click="onClickTag('교수학습')">#교수학습</span>
        </li>
        <li>
          <span @click="onClickTag('원격수업')">#원격수업</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import searchSupport from '../search/search_support';

const URL_INSERT_SEARCH_WORD = "/api/user/v1/portalUserSearchApi_insert";

export default {
  name: "TheMainSearch",
  data() {
    return {
      searchInput: '',
      // focus 될때 prefixbar 보이기
      prefixBarVisible: true,
    }
  },
  mounted() {
    const data = searchSupport.getSearchWords( searchSupport.LOCALSTORAGE_KEY_SEARCH_WORDS_IN_MAIN );
    this.links = data;
  },
  methods: {
    // 태그 클릭시 호출
    onClickTag( word ) {
      this.goSearch( word );
    },
    // 아무역할 없음
    onSelectWord( e ) {
    },

    // 자동단어 출력 관련
    querySearch( queryString, cb ) {
      const links = this.links;
      const linksSort = links.sort( ( a, b ) => new Date( b.date ) - new Date( a.date ) );
      const results = queryString ? linksSort.filter( this.createFilter( queryString ) ) : links;
      // call callback function to return suggestions
      cb( results );
    },
    createFilter( queryString ) {
      return ( link ) => {
        return (link.word.toLowerCase().indexOf( queryString.toLowerCase() ) === 0);
      };
    },
    /**
     *  메인 searchbox의 하단 검색 목록리스트를 열어줍니다
     */
    onSearchCompleteOpen( e ) {

      const searchPoper = document.body.querySelector( '.search-popper' );

      if ( searchPoper !== undefined ) {
        searchPoper.style.display = "";
      }
      this.prefixBarVisible = false;
    },

    /**
     * 검색버튼 누를때
     */
    async onClickSearch() {
      if ( this.searchInput.trim() === '' ) {
        alert( '검색어를 입력해주세요' )
      }
      else {
        // 검색어 저장
        await searchSupport.saveSearchWordsInMainPage( this.searchInput );

        // 검색처리
        this.goSearch( this.searchInput );

        // 인기 검색어 insert
        this.insertSearchWord();
      }
    },

    // 인기 검색어를 위해 db에 저장합니다.
    insertSearchWord() {

      this.$axios.post( URL_INSERT_SEARCH_WORD, {searchWord: this.searchInput} );
    },

    // 검색어 바로 클릭시
    async goSearch( word ) {
      await localStorage.setItem( "SEARCH_WORD", word );
      this.$router.push( {
        path: '/portal/user/search/search_main',
        query: {word: word},
      } );

      await this.$axios.post( URL_INSERT_SEARCH_WORD, {searchWord: word} );
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
