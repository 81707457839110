<template>
    <div class="loading">
        <img src="@/assets/images/common/loading/loading.svg" alt="Loading..." />
    </div>
</template>
<script>
export default {

}
</script>
