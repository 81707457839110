import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
import formatUtils from '~/plugins/core/utils/formatUtils';

/**
 * 포탈 사이트용 Format 유틸
 *
 */
const portalFormatUtils = (() => {

  return {


  }
})();

export default portalFormatUtils;