<template>
  <div>error!! : {{error.statusCode}} - {{error.message}} </div>
</template>

<script>
export default {
    name:'Error',
    props: {
        error:{
            type: Object,
            default: null
        }
    },
}
</script>