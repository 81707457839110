import qrCodeUtils from '~/plugins/core/utils/service/barcode/qrCodeUtils'

/**
 * Service 유틸 모음
 * 
 */
const serviceUtils = {

  // qr 코드 관련
  qrCodeUtils,

}

export default serviceUtils;