/**
 *
 * @type {{}}
 */
const typeValidationUtils = ( () => {

  function isEmptyObject( obj ) {

    // eslint-disable-next-line no-global-assign
    for ( name in obj ) {
      return false;
    }
    return true;
  }

  function isEmpty( value ) {

    if ( "number" === typeof value && 0 < value ) {
      return false;
    }

    return (
      isEmptyObject( value )
      || null === value
      || 0 === value.length
      || undefined === value
      || false === value
      || "" === value
    );
  }

  function _typeOf( obj ) {
    return Object.prototype.toString.call( obj ).match( /\s([a-zA-Z]+)/ )[1].toLowerCase();
  }

  return {

    // 공백 및 null 체크
    isEmpty( value ) {
      return isEmpty( value );
    },

    // 데이터가 존재
    isNotEmpty( value ) {
      return !isEmpty( value );
    },

    /**
     * number 타입인지 확인합니다.
     *
     * @param value
     * @return {boolean}
     */
    isNumber( value ) {

      const numberCheck = /^\d+$/;
      return numberCheck.test( value );
    },

    /**
     * 오브젝트인지 확인합니다.
     *
     * @param obj
     * @return { boolean }
     */
    isObject( obj ) {
      return ("object" === _typeOf( obj ) && null !== obj && obj.constructor);
    },

    /**
     * boolean 타입인지 확인합니다.
     *
     * @param value
     * @return {boolean}
     */
    isBoolean( value ) {
      return "boolean" === _typeOf( value );
    },

    /**
     * 함수인지 확인합니다.
     *
     * @param fn
     * @return {boolean}
     */
    isFunction( fn ) {
      return ("function" === _typeOf( fn ) || "asyncfunction" === _typeOf( fn ));
    },

    /**
     * 해당 값이 undefined 인지 확인합니다
     *
     * @param value
     * @return {boolean}
     */
    isUndefined( value ) {
      return "undefined" === _typeOf( value );
    },

    /**
     * string 타입인지 확인합니다.
     *
     * @param value
     * @return {boolean}
     */
    isString( value ) {
      return "string" === _typeOf( value );
    },

    /**
     * 배열 타입인지 확인합니다.
     *
     * @param value
     * @return {boolean}
     */
    isArray( value ) {

      return Array.isArray( value );
    },

    /**
     * 음수 양수 소수점 포함하여 체크합니다.
     *
     * @param value
     * @return {boolean}
     */
    isNumberIncludeFloat( value ) {
      const regex = /^-?\d+(\.\d+)?$/;
      return regex.test( value );
    },

    /**
     * 모바일인지 확인합니다.
     *
     * @return {boolean|boolean}
     */
    isMobile() {

      const agent = navigator.userAgent.toLowerCase();

      const isIos = /(ipod|iphone|ipad)/i.test( agent ); // ios
      const isIpad = /(ipad)/i.test( agent ); // ipad
      const isAndroid = /android/i.test( agent ); // android
      const detectMob = window.innerWidth <= 1024;

      return isIos || isIpad || isAndroid || detectMob;
    },


  }
})();

export default typeValidationUtils;