import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
import formatUtils from '~/plugins/core/utils/formatUtils';

/**
 * 사용자 유틸
 * @type {{}}
 */
const userUtils = (() => {

  const { isEmpty } = typeValidationUtils;
  const { formatBirthday } = formatUtils;

  return {

    /**
     * 사용자 생년월일을 '-'형태로 합칩니다.
     *
     * @param userWInfo
     * @return {string}
     */
    formatBirthdayByUser( userWInfo ) {

      const birthYear = userWInfo.birthYear;
      const birthMonth = userWInfo.birthMonth;
      const birthDay = userWInfo.birthDay;

      if ( isEmpty( birthYear ) || isEmpty( birthMonth ) || isEmpty( birthDay ) ) {
        return '-';
      }

      return formatBirthday( birthYear + birthMonth + birthDay );
    },
  }
})();

export default userUtils;