<template>
  <footer id="footer" class="footer" :class="{'is-main' : isMain}">
    <div class="inner">
      <!-- quick menu -->
      <!-- intro area -->
      <div class="footer-intro">
        <div class="intro-inner">
          <div class="future-logo">
            <img src="@/assets/images/portal/icon/main/icon_footer-copy.png"
                 alt="미래교육을 디자인하는 창의융합교육의 플랫폼"/>
          </div>
          <div class="footer-dropdown" v-click-outside="onClickOutside">
            <!-- dropdown -->
            <div class="flex">
              <div class="select-box down-line-select">
                <el-button type="select" class="down-line-btn" @click="dropDownToggle('agency')"
                           :class="{'is-active' : dropdownShown.agency}">
                  <span>직속기관</span>
                  <span class="material-icons">arrow_drop_down</span>
                </el-button>
                <!-- 직속기관 -->
                <div class="down-line-menu">
                  <div class="scroll-element" v-bar="{ preventParentScroll: true }">
                    <ul class="line-list">
                      <li>
                        <a href="https://keric.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청교육연구원</a>
                      </li>
                      <li>
                        <a href="https://geti.gwe.go.kr/" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청교육연수원</a>
                      </li>
                      <li>
                        <a href="https://gwedu.go.kr" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청교육과학정보원</a>
                      </li>
                      <li>
                        <a href="https://jinro.gwe.go.kr/" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청진로교육원</a>
                      </li>
                      <li>
                        <a href="https://saimdang.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청사임당교육원</a>
                      </li>
                      <li>
                        <a href="https://kwsei.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청학생교육원</a>
                      </li>
                      <li>
                        <a href="https://gttc.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청교직원수련원</a>
                      </li>
                      <li>
                        <a href="https://giei.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청국제교육원</a>
                      </li>
                      <li>
                        <a href="https://gwch.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청유아교육원</a>
                      </li>
                      <li>
                        <a href="https://gts.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청통일교육원</a>
                      </li>
                      <li>
                        <a href="https://lib.gwe.go.kr/chuncheonecc/index.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청춘천교육문화관</a>
                      </li>
                      <li>
                        <a href="https://lib.gwe.go.kr/wjecc/index.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청원주교육문화관</a>
                      </li>
                      <li>
                        <a href="https://lib.gwe.go.kr/gnecc/index.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청강릉교육문화관</a>
                      </li>
                      <li>
                        <a href="https://lib.gwe.go.kr/samecc/index.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청삼척교육문화관</a>
                      </li>
                      <li>
                        <a href="https://lib.gwe.go.kr/sokecc/index.do" target="_blank"
                           @click="dropdownShown['agency'] = false">강원특별자치도교육청속초교육문화관</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="select-box down-line-select">
                <el-button type="select" class="down-line-btn" @click="dropDownToggle('support')"
                           :class="{'is-active' : dropdownShown.support}">
                  <span>지역교육지원청</span>
                  <span class="material-icons">arrow_drop_down</span>
                </el-button>
                <!-- 교육청 -->
                <div class="down-line-menu">
                  <div class="scroll-element" v-bar="{ preventParentScroll: true }">
                    <ul class="line-list">
                      <li>
                        <a href="https://gwcce.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도춘천교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwwjed.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도원주교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwgned.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도강릉교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwsyed.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도속초양양교육지원청</a>
                      </li>
                      <li>
                        <a href="https://kwthe.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도동해교육지원청</a>
                      </li>
                      <li>
                        <a href="https://kwtbe.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도태백교육지원청</a>
                      </li>
                      <li>
                        <a href="https://sce.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도삼척교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwhce.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도홍천교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwhsed.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도횡성교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwywed.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도영월교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwpce.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도평창교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwjsed.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도정선교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwcwed.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도철원교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwhced.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도화천교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwyged.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도양구교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwijed.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도인제교육지원청</a>
                      </li>
                      <li>
                        <a href="https://gwgsed.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['support'] = false">강원특별자치도고성교육지원청</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="select-box down-line-select">
                <el-button type="select" class="down-line-btn" @click="dropDownToggle('education')"
                           :class="{'is-active' : dropdownShown.education}">
                  <span>시·도 교육기관</span>
                  <span class="material-icons">arrow_drop_down</span>
                </el-button>
                <!-- 시도교육기관관 -->
                <div class="down-line-menu">
                  <div class="scroll-element" v-bar="{ preventParentScroll: true }">
                    <ul class="line-list">
                      <li>
                        <a href="https://www.moe.go.kr/main.do?s=moe" target="_blank"
                           @click="dropdownShown['education'] = false">교육부</a>
                      </li>
                      <li>
                        <a href="https://www.gwe.go.kr/main/index.do" target="_blank"
                           @click="dropdownShown['education'] = false">강원특별자치도교육청</a>
                      </li>
                      <li>
                        <a href="https://www.goe.go.kr/" target="_blank"
                           @click="dropdownShown['education'] = false">경기도교육청</a>
                      </li>
                      <li>
                        <a href="https://www.gne.go.kr/index.gne?contentsSid=1384" target="_blank"
                           @click="dropdownShown['education'] = false">경상남도교육청</a>
                      </li>
                      <li>
                        <a href="http://www.gbe.kr/main/main.do" target="_blank"
                           @click="dropdownShown['education'] = false">경상북도교육청</a>
                      </li>
                      <li>
                        <a href="http://www.gen.go.kr/main/main.php" target="_blank"
                           @click="dropdownShown['education'] = false">광주광역시교육청</a>
                      </li>
                      <li>
                        <a href="http://www.dge.go.kr/main/main.do" target="_blank"
                           @click="dropdownShown['education'] = false">대구광역시교육청</a>
                      </li>
                      <li>
                        <a href="http://www.dje.go.kr/intro_corona.html" target="_blank"
                           @click="dropdownShown['education'] = false">대전광역시교육청</a>
                      </li>
                      <li>
                        <a href="http://www.pen.go.kr/" target="_blank"
                           @click="dropdownShown['education'] = false">부산광역시교육청</a>
                      </li>
                      <li>
                        <a href="http://www.sen.go.kr/" target="_blank"
                           @click="dropdownShown['education'] = false">서울특별시교육청</a>
                      </li>
                      <li>
                        <a href="https://www.sje.go.kr/intro/sje/index.html?sysId=sje"
                           target="_blank"
                           @click="dropdownShown['education'] = false">세종특별자치시교육청</a>
                      </li>
                      <li>
                        <a href="https://www.use.go.kr/" target="_blank"
                           @click="dropdownShown['education'] = false">울산광역시교육청</a>
                      </li>
                      <li>
                        <a href="https://www.ice.go.kr/intro/ice/index.html?sysId=ice" target="_blank"
                           @click="dropdownShown['education'] = false">인천광역시교육청</a>
                      </li>
                      <li>
                        <a href="https://www.jne.go.kr/main/main.do" target="_blank"
                           @click="dropdownShown['education'] = false">전라남도교육청</a>
                      </li>
                      <li>
                        <a href="https://www.jbe.go.kr/intro/intro.html" target="_blank"
                           @click="dropdownShown['education'] = false">전라북도교육청</a>
                      </li>
                      <li>
                        <a href="https://www.jje.go.kr/index.jje" target="_blank"
                           @click="dropdownShown['education'] = false">제주특별자치도교육청</a>
                      </li>
                      <li>
                        <a href="https://www.cne.go.kr/cne_intro.html" target="_blank"
                           @click="dropdownShown['education'] = false">충청남도교육청</a>
                      </li>
                      <li>
                        <a href="https://www.cbe.go.kr/" target="_blank"
                           @click="dropdownShown['education'] = false">충청북도교육청</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="select-box down-line-select">
                <el-button type="select" class="down-line-btn" @click="dropDownToggle('science')"
                           :class="{'is-active' : dropdownShown.science}">
                  <span>과학·정보원 기관</span>
                  <span class="material-icons">arrow_drop_down</span>
                </el-button>
                <!-- 시도교육기관관 -->
                <div class="down-line-menu">
                  <div class="scroll-element" v-bar="{ preventParentScroll: true }">
                    <ul class="line-list">
                      <li>
                        <a href="https://keric.gwe.go.kr/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">강원특별자치도교육청교육연구원</a>
                      </li>
                      <li>
                        <a href="https://www.serii.re.kr/" target="_blank"
                           @click="dropdownShown['science'] = false">서울특별시교육연구정보원</a>
                      </li>
                      <li>
                        <a href="https://ssp.sen.go.kr/" target="_blank"
                           @click="dropdownShown['science'] = false">서울특별시과학전시관</a>
                      </li>
                      <li>
                        <a href="https://home.pen.go.kr/busanedu/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">부산광역시교육연구정보원</a>
                      </li>
                      <li>
                        <a href="https://home.pen.go.kr/bicce/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">부산광역시과학교육원</a>
                      </li>
                      <li>
                        <a href="http://www.dge.go.kr/dferi/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">대구광역시미래교육연구원</a>
                      </li>
                      <li>
                        <a href="http://www.dge.go.kr/dicce/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">대구광역시창의융합교육원</a>
                      </li>
                      <li>
                        <a href="http://ienet.ice.go.kr/index.do?sso=ok" target="_blank"
                           @click="dropdownShown['science'] = false">인천광역시교육과학정보원</a>
                      </li>
                      <li>
                        <a href="https://desre.djsch.kr/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">대전교육과학연구원</a>
                      </li>
                      <li>
                        <a href="https://dei.djsch.kr/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">대전교육정보원</a>
                      </li>
                      <li>
                        <a href="http://gice.gen.go.kr/" target="_blank"
                           @click="dropdownShown['science'] = false">광주광역시창의융합교육원</a>
                      </li>
                      <li>
                        <a href="http://geris.gen.go.kr/" target="_blank"
                           @click="dropdownShown['science'] = false">광주광역시교육연구정보원</a>
                      </li>
                      <li>
                        <a href="https://www.ulsanedu.kr/index.do" target="_blank"
                           @click="dropdownShown['science'] = false">울산광역시교육연구정보원</a>
                      </li>
                      <li>
                        <a href="https://www.gie.re.kr/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">경기도교육연구원</a>
                      </li>
                      <li>
                        <a href="https://www.cbnse.go.kr/main.php" target="_blank"
                           @click="dropdownShown['science'] = false">충청북도자연과학연구원</a>
                      </li>
                      <li>
                        <a href="https://www.cberi.go.kr/home/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">충청북도교육연구정보원</a>
                      </li>
                      <li>
                        <a href="http://home.edus.or.kr/index.do" target="_blank"
                           @click="dropdownShown['science'] = false">충청남도교육연구정보원</a>
                      </li>
                      <li>
                        <a href="https://www.jbedu.kr/index.jbedu" target="_blank"
                           @click="dropdownShown['science'] = false">전라북도교육연구정보원</a>
                      </li>
                      <li>
                        <a href="https://www.jnei.go.kr/jneinew/index.do" target="_blank"
                           @click="dropdownShown['science'] = false">전라남도교육연구정보원</a>
                      </li>
                      <li>
                        <a href="https://jncce.da.jne.kr/user/jnse_da/index.action" target="_blank"
                           @click="dropdownShown['science'] = false">전라남도과학교육원</a>
                      </li>
                      <li>
                        <a href="http://www.gbe.kr/gber/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">경상북도교육청연구원</a>
                      </li>
                      <li>
                        <a href="http://www.gbelib.kr/geic/index.do" target="_blank"
                           @click="dropdownShown['science'] = false">경상북도교육청정보센터</a>
                      </li>
                      <li>
                        <a href="https://gnei.gne.go.kr/gnei/main.do" target="_blank"
                           @click="dropdownShown['science'] = false">경상남도교육청교육연구정보원</a>
                      </li>
                      <li>
                        <a href="https://org.jje.go.kr/jiei/index.jje" target="_blank"
                           @click="dropdownShown['science'] = false">제주국제교육원</a>
                      </li>
                      <li>
                        <a href="https://org.jje.go.kr/cisec/index.jje" target="_blank"
                           @click="dropdownShown['science'] = false">제주융합과학연구원</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- info area -->
      <div class="footer-item-box">
        <div class="item-area">
          <div class="logo-box">
            <p class="logo footer-logo" @click="goHome">
              <img alt="강원특별자치도교육청교육과학정보원 메인 이동 로고"
                   src="@/assets/images/common/logo/logo_white.png"/>
            </p>
          </div>
          <div>
            <div class="footer-info">
              <div class="info-inner">
                <ul class="policy-list">
                  <li @click="goCopyrightPolicy">
                    <span>저작권정책</span>
                  </li>
                  <li class="color-orange" @click="goPrivatePolicy">
                    <span>개인정보처리방침</span>
                  </li>
<!--                  <li class="web-visible" @click="goPrivatePolicy">-->
<!--                    <span>고객센터</span>-->
<!--                  </li>-->
                  <li @click="goTermsofuse">
                    <span>이용약관</span>
                  </li>
                  <li class="web-visible" @click="openMailModal">
                    <span>관리자메일</span>
                  </li>
                  <li @click="goContactNumber">
                    <span>담당자 연락처</span>
                  </li>
                  <li class="web-visible" @click="goRemoteSupport">
                    <span>원격지원</span>
                  </li>
                  <li class="web-visible">
                    <a @click="goMessenger"><span>강원에듀원 메신저</span></a>
                    <span class="material-icons">file_download</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-address">
              <div class="address-inner">
                <div class="site-info" :class="{ 'is-active': isFooterInfoVisible }">
                  <div class="footer-logo-wrapper">
                    <div class="mob-visible footer-logo" @click="goHome">
                      <img alt="강원특별자치도교육청교육과학정보원 메인 이동 로고"
                           src="@/assets/images/common/logo/logo_white.png"/>
                    </div>
                    <button class="footer-more-btn" @click="toggleFooterInfo">
                      <span class="material-icons">expand_more</span>
                    </button>
                  </div>
                  <div class="footer-info-wrapper">
                    <address class="address">26455 강원특별자치도 원주시 운곡로 220</address>
                    <div class="footer-info__contact">시스템 기술 문의 : <a href="tel:1533-3280">1533-3280</a></div>
                  </div>
                  <p class="copyright">
                    &copy; 2021. Gangwon State Education Science & Information Institute. All Right Reserved.
                  </p>
                </div>
                <!-- 단축 URL의 쿠키를 가져오기 위해 단축 URL 이미지 추가 -->
                <img src="https://gw1.kr/image_og.png" alt="Image" style="display: none;"/>
              </div>
            </div>
          </div>
        </div>
        <span class="text-drag" v-text="'(' + currentSV + ')'"></span>
      </div>
    </div>
    <el-dialog :visible.sync="mailModalVisible" title="메일보내기" width="45rem"
               top="6rem" :append-to-body="true"
               @close="closeMailModal()">
      <div>
        <div class="table-custom theme-left linear">
          <table class="">
            <colgroup>
              <col style="width: 20%"/>
              <col style="width: auto"/>
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">
                <label for="emailSender">
                  발신자<em class="required">*</em>
                </label>
              </th>
              <td>
                <el-input id="emailSender" v-model="mailCallerInput" class=""
                          placeholder=""></el-input>
              </td>
            </tr>
            <tr>
              <th scope="row">수신자</th>
              <td>
                <span v-text="`${mailReceiverInput} (강원특별자치도교육청교육과학정보원)`"></span>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="mailTitle">
                  메일제목<em class="required">*</em>
                </label>
              </th>
              <td>
                <el-input id="mailTitle" v-model="mailTitleInput" class=""
                          placeholder=""></el-input>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="mailContent">
                  메일내용
                  <em class="required">*</em>
                </label>
              </th>
              <td>
                <div class="scroll-element" v-bar="{ preventParentScroll: true }">
                  <el-input
                    id="mailContent"
                    type="textarea"
                    placeholder=""
                    :rows="10"
                    v-model="mailDetailTextarea"
                    class="pd0"
                  >
                  </el-input>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="size-md" @click="sendMail()">보내기</el-button>
        <el-button type="gray" class="size-md" @click="closeMailModal()">취소</el-button>
      </div>
    </el-dialog>
  </footer>
</template>
<script>
import vClickOutside from 'v-click-outside'
import portalApiUrl from '~/constant/site/portal/portalApiUrl';


const URL_SERVER_INFO = "/server/getServerInfo";

const REAL_SERVER1 = "192.168.1.35";
const REAL_SERVER2 = "192.168.1.36";
const DEV_SERVER = "211.110.1.37";

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    isMain: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      todayVisitCount: 0, // 오늘 오신분 수
      totalVisitCount: 0, // 전체 카운트수

      // dropdown
      dropdownShown: {
        agency: false,
        support: false,
        education: false,
        science: false,
      },

      mailModalVisible: false,

      mailCallerInput: "",
      // 실제 운영서버에서는 김영식 연구사님 계정으로 사용해야함 -> 아직 테스트 중이므로 리마커블 계정으로 임시 사용
      mailReceiverInput: this.$constant.PORTAL_SYSTEM_MANAGER.EMAIL,
      // mailReceiverInput  : "remarkablesoft02@gmail.com",
      mailTitleInput: "",
      mailDetailTextarea: "",

      // 서버 IP 주소
      currentSV: "",

      // 푸터 정보 더보기 상태
      isFooterInfoVisible: false,
    };
  },
  created() {
    this.getTodayAndTotalCount();

    this.getServerInfo();
  },
  // computed : {

  // },
  // watch    : {

  // },
  methods: {

    onClickOutside() {

      if ( this.dropdownShown.agency ) {
        this.dropdownShown.agency = false;
      }

      if ( this.dropdownShown.support ) {
        this.dropdownShown.support = false;
      }

      if ( this.dropdownShown.education ) {
        this.dropdownShown.education = false;
      }

      if ( this.dropdownShown.science ) {
        this.dropdownShown.science = false;
      }
    },

    goHome() {
      this.$router.push( "/portal/user/main/user_main" );
    },

    getTodayAndTotalCount() {

      let param = {}

      this.$axios.post( portalApiUrl.USER.VISIT.GET_TODAY_TOTAL_COUNT, param ).then( res => {

        //console.log ( res.data );

        if ( this.$validate.isEmpty( res.data ) ) {
          return "";
        }

        // console.log( "오늘 오신분 : " + res.data.TodayVisitCount );
        // console.log( "전체카운트 : " + res.data.TotalVisitCount );

        this.todayVisitCount = this.$util.formatUtils.addCommas( res.data.TodayVisitCount );
        this.totalVisitCount = this.$util.formatUtils.addCommas( res.data.TotalVisitCount );

      } );

    },

    dropDownToggle( type ) {
      let showYn = this.dropdownShown[type];

      this.dropdownShown = {
        agency: false,
        support: false,
        education: false,
        science: false,
      };

      this.dropdownShown[type] = !showYn;
    },

    goCopyrightPolicy () {
      this.$router.push(
        '/portal/user/customerService/copyright/copyrightProtect' );
    },

    goPrivatePolicy() {
      // console.log( '#### process.env.NUXT_ENV_STAGE', process.env.NUXT_ENV_STAGE )

      this.$router.push(
        '/portal/user/customerService/privatePolicy/personalInformation/personalInformation' );
    },

    goContactNumber() {

      // console.log( '#### process.env.NUXT_ENV_STAGE', process.env.NUXT_ENV_STAGE )

      this.$router.push( '/portal/user/common/commonScreen?menuOid=1SuAUMB10RM' );

    },

    goTermsofuse() {

      // console.log( '#### process.env.NUXT_ENV_STAGE', process.env.NUXT_ENV_STAGE )

      this.$router.push( '/portal/user/common/commonScreen?menuOid=1T5heDSY011' );

    },

    openMailModal() {
      this.mailModalVisible = true;
    },

    closeMailModal() {
      this.mailCallerInput = "";
      this.mailTitleInput = "";
      this.mailDetailTextarea = "";

      this.mailModalVisible = false;
    },

    sendMail() {

      let isValid = this.sendMailValid();

      if ( !isValid ) {
        return;
      }

      let receiverList = [];

      let portalManager = {
        email: this.mailReceiverInput,
        name: "포탈 시스템 관리자",
      };

      receiverList.push( portalManager );

      let mailInfo = {
        partOid: this.$constant.SYSTEM_OID.PORTAL, // 포탈 시스템 OID
        messageTypeMode: this.$constant.MESSAGE_TYPE_MODE.EMAIL, // 메세지 타입 = 메일
        sendTypeFlag: this.$constant.MESSAGE_SEND_TYPE_FLAG.USER.KEY, // 사용자 개별발송

        name: this.mailTitleInput,
        contents: this.mailDetailTextarea,
        senderEmail: this.mailCallerInput,
        receiverList: receiverList,
      }

      let loginUser = this.$store.state.common.login.loginUser

      if ( this.$validate.isNotEmpty( loginUser ) ) {
        mailInfo.inputUser = this.$store.state.common.login.loginUser.userOid;
      }

      this.$axios.post( portalApiUrl.USER.MESSAGE.SEND_MESSAGE, mailInfo ).then( res => {
        this.$util.rsAlertUtils.rsAlert( { title: "발송 완료", contents: "메일 발송이 완료되었습니다.", state: "success" } );
        this.closeMailModal();
      } )
    },

    sendMailValid() {
      if ( this.$validate.isEmpty( this.mailCallerInput ) || !this.$validate.isEmail(
        this.mailCallerInput ) ) {
        this.$util.rsAlertUtils.rsAlert( { title: "입력 확인", contents: "발신자 이메일 형식이 올바르지 않습니다." } );
        return false;
      }

      if ( this.$validate.isEmpty( this.mailTitleInput ) ) {
        this.$util.rsAlertUtils.rsAlert( { title: "입력 확인", contents: "발신자 이메일 형식이 올바르지 않습니다." } );
        return false;
      }

      if ( this.$validate.isEmpty( this.mailDetailTextarea ) ) {
        this.$util.rsAlertUtils.rsAlert( { title: "입력 확인", contents: "메일 내용을 입력해주세요." } );
        return false;
      }

      return true;
    },

    async getServerInfo() {

      if ( this.$validate.isNotEmpty( sessionStorage.getItem( "currentSV" ) ) ) {
        this.currentSV = sessionStorage.getItem( "currentSV" );
        return;
      }

      this.$axios.get( URL_SERVER_INFO ).then( ( res ) => {

        // 서버 버전 바인딩
        if ( res.data.ip.indexOf( REAL_SERVER1 ) > -1 ) {
          sessionStorage.setItem( "currentSV", "SV:1" );
        }
        else if ( res.data.ip.indexOf( REAL_SERVER2 ) > -1 ) {
          sessionStorage.setItem( "currentSV", "SV:2" );
        }
        else if ( res.data.ip.indexOf( DEV_SERVER ) > -1 ) {
          sessionStorage.setItem( "currentSV", "SV:DEV" );
        }
        else {
          sessionStorage.setItem( "currentSV", res.data.ip );
        }

        this.currentSV = sessionStorage.getItem( "currentSV" );

      } )
    },

    goRemoteSupport() {
      window.open( "https://939.co.kr/gwedu/" );
    },

    goMessenger() {
      window.open( "/messenger/setup_kericmessenger.exe" );
    },

    // 푸터 정보 더보기 토글
    toggleFooterInfo() {
      this.isFooterInfoVisible = !this.isFooterInfoVisible;

      // 푸터 정보 보일 때 스크롤을 맨 하단으로 내리기
      if ( this.isFooterInfoVisible ) {
        this.$nextTick( () => {
          window.scrollTo( {
            top: document.body.scrollHeight,
          } );
        } );
      }
    }
  }
};
</script>
<style>
p::selection {
  background: #fff;
  color: #ff0000;
}
</style>
