/**
 * 타이머 유틸
 */
const timerUtils = (() => {

  /**
   *  초를 param으로 받으면  ex) 00 : 00 이런형식으로 리턴
   *
   * @param time
   * @returns {string}
   */
  function formatTimerToMMSS( time ) {

    console.log("time", time )

    let minutes = Math.floor(time / 60);
    let seconds = time % 60;

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return minutes + ":" + seconds;
  }

  return {

    // 시간을 설정하지 않으면 5분으로 기본값 셋팅.
    TIMER_TIME : {
      FULL_TIME : 60 * 5,
      FIX_FULL_TIME : 60 * 5,
    },

    /**
     * 타이머 시간을 세팅합니다.
     *
     * @param {number} seconds
     */
    setCountTimer( seconds ) {

      this.TIMER_TIME.FIX_FULL_TIME = seconds;
      this.TIMER_TIME.FULL_TIME = seconds;
    },

    resetTimer() {

      this.TIMER_TIME.FULL_TIME = this.TIMER_TIME.FIX_FULL_TIME;
    },

    /**
     * 남은 시간을 반환합니다.
     *
     * @return {number|string}
     */
    getRestTime() {

      this.TIMER_TIME.FULL_TIME--;

      // 남은 시간이 없다면 0을 반환합니다.
      return 0 >= this.TIMER_TIME.FULL_TIME ? 0 : formatTimerToMMSS( this.TIMER_TIME.FULL_TIME );
    },


  }

})();

export default timerUtils;
