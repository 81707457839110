/**
 * 포탈에서 사용하는 Store 정보
 */
const __storeAuthModule   = "core/auth";             // 유저 정보 : 로그인 구조
const __storeLoginModule   = "common/login";         // 유저 정보 : 기존 구조
const __storeEnvConfigModule   = "common/envConfig"; // 환경 설정
const __storeloadingModule   = "common/loading";     // 로딩
const __storeFetchModule  = "core/fetch";

const coreStoreName = {

  // 로그인 : Core에서
  auth: {
    action: {
      setUser:    __storeAuthModule + "/setUser"
    },
    getter: {
      getUser:    __storeAuthModule + "/getUser",
    }
  },

  // 로그인 : 기존 구조에서
  login: {
    action: {
      setUser  :    __storeLoginModule + "/loginUser",
      logout   :    __storeLoginModule + "/logout"
    },
    getter: {
      getUser  :    __storeLoginModule + "/loginUserInfo",
    },
  },

  // EnvConfig
  envConfig: {
    action : {
      setPostingCount    :    __storeEnvConfigModule + "/setPostingDisplayCount",  // Posting 카운트
      setPwdGenerateRule :    __storeEnvConfigModule + "/setPwdGenerateRule",      // 비밀번호 규칙
      setEnvConfig       :    __storeEnvConfigModule + "/setEnvConfig",            // 그 외
    }
  },

  // loading
  loading : {
    action : {
      add       : __storeloadingModule + "/addLoadingList",
      delete    : __storeloadingModule + "/deleteLoadingList",
      deleteAll : __storeloadingModule + "/deleteAllLoadingList"
    },

    getter : {
      list : __storeloadingModule + "/getLoadingList"
    }
  }

};

export default coreStoreName;
