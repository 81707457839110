export const state = () => ( {

  adminMenuListByRole : [],
  userMenuListByRole : [],
  beforeLoginUserMenuListByRole : [],
  roleMenuInfo : {},
} )

export const mutations = {

  SET_ADMIN_MENU_LIST_BY_ROLE( state, menuList ) {
    state.adminMenuListByRole = menuList;
  },
  SET_BEFORE_USER_MENU_LIST_BY_ROLE( state, beforeLoginUserMenuList ) {
    state.beforeLoginUserMenuListByRole = beforeLoginUserMenuList;
  },
  SET_USER_MENU_LIST_BY_ROLE( state, menuList ) {
    state.userMenuListByRole = menuList;
  },
  SET_ROLE_MENU_INFO( state, roleMenuInfo ) {
    state.roleMenuInfo = roleMenuInfo;
  },
}

// 암호화되서 넘어온 값을 복호화 후 파싱하여 저장합니다.
function encryptAndParseByJson( data ) {

  if ( $nuxt.$validate.isEmpty( data ) ) {
    return [];
  }

  return JSON.parse( $nuxt.$util.encryptUtils.decryptAesValue( data ) );
}

export const actions = {

  async getAdminMenuListByRole( { commit } ) {

    const URL_LIST_BY_ROLE = "/api/manager/v1/portalManagerMenuApi_listByRole";
    
        
    // console.log ( "2222222222222222" );
    // console.log ( "process.env.IS_REAL_YN : " + process.env.IS_REAL_YN);
    // console.log ( "$nuxt.$constant.FLAG_YN.YES : " + $nuxt.$constant.FLAG_YN.YES);
    // console.log ( "$nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN : " + $nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN);
    // console.log ( "2222222222222222" );

    // 2024.04.05 신다은 [SM20240405] 운영일 경우에만 암호화 처리하도록 수정
    const className = $nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN ?
      $nuxt.$util.encryptUtils.encryptAesValue( $nuxt.$constant.MENU_CLASS_NAME.ADMIN_MENU.KEY ) : $nuxt.$constant.MENU_CLASS_NAME.ADMIN_MENU.KEY;

    const param = {
      className,
    }
    // useYn 이 N 이어도 menuMagnagementYn 이 Y 일 수 있어 주석처리
    // param.useYn = $nuxt.$constant.FLAG_YN.YES;

    await $nuxt.$axios.post( URL_LIST_BY_ROLE, param ).then( res => {

      if ( $nuxt.$validate.isEmpty( res.data ) ) {
        return;
      }

      // 2024.04.05 신다은 [SM20240405] 운영일 경우에만 암호화 처리하도록 수정
      const menuListByRole = $nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN ? encryptAndParseByJson( res.data ) : res.data;

      // 암호화되서 넘어온 값을 복호화 후 파싱하여 저장합니다.
      commit( "SET_ADMIN_MENU_LIST_BY_ROLE", menuListByRole );
    } );
  },

  async getBeforeLoginUserMenuListByRole( { commit } ) {

    const URL_LIST_BY_ROLE = "/api/user/v1/portalUserMenuApi_listByUserRole";

    // 2024.04.05 신다은 [SM20240405] 운영일 경우에만 암호화 처리하도록 수정
    const className = $nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN ?
      $nuxt.$util.encryptUtils.encryptAesValue( $nuxt.$constant.MENU_CLASS_NAME.USER_MENU.KEY ) : $nuxt.$constant.MENU_CLASS_NAME.USER_MENU.KEY;

    const roleOid = $nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN ?
      $nuxt.$util.encryptUtils.encryptAesValue( $nuxt.$constant.ROLE_OID.BEFORE_LOGIN.ROLE_OID ) : $nuxt.$constant.ROLE_OID.BEFORE_LOGIN.ROLE_OID;

    let param = {
      className,
      roleOid,
    };

    await $nuxt.$axios.post( URL_LIST_BY_ROLE, param ).then( res => {

      if ( $nuxt.$validate.isEmpty( res.data ) ) {
        return;
      }

      // 2024.04.05 신다은 [SM20240405] 운영일 경우에만 암호화 처리하도록 수정
      const menuListByRole = $nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN ? encryptAndParseByJson( res.data ) : res.data;

      // 암호화되서 넘어온 값을 복호화 후 파싱하여 저장합니다.
      commit( "SET_BEFORE_USER_MENU_LIST_BY_ROLE", menuListByRole );
    } );
  },

  async getUserMenuListByRole( { commit } ) {

    const URL_LIST_BY_ROLE = "/api/manager/v1/portalManagerMenuApi_listByRole";

    // console.log ( "111111111111111" );
    // console.log ( "process.env.IS_REAL_YN : " + process.env.IS_REAL_YN);
    // console.log ( "$nuxt.$constant.FLAG_YN.YES : " + $nuxt.$constant.FLAG_YN.YES);
    // console.log ( "$nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN : " + $nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN);
    // console.log ( "111111111111111" );

    // 2024.04.05 신다은 [SM20240405] 운영일 경우에만 암호화 처리하도록 수정
    const className = $nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN ?
      $nuxt.$util.encryptUtils.encryptAesValue( $nuxt.$constant.MENU_CLASS_NAME.USER_MENU.KEY ) : $nuxt.$constant.MENU_CLASS_NAME.USER_MENU.KEY;

    const param = {
      className,
    }
    // useYn 이 N 이어도 menuMagnagementYn 이 Y 일 수 있어 주석처리
    // param.useYn = $nuxt.$constant.FLAG_YN.YES;

    await $nuxt.$axios.post( URL_LIST_BY_ROLE, param ).then( res => {

      if ( $nuxt.$validate.isEmpty( res.data ) ) {
        return;
      }

      // 2024.04.05 신다은 [SM20240405] 운영일 경우에만 암호화 처리하도록 수정
      const menuListByRole = $nuxt.$constant.FLAG_YN.YES === process.env.IS_REAL_YN ? encryptAndParseByJson( res.data ) : res.data;

      // 암호화되서 넘어온 값을 복호화 후 파싱하여 저장합니다.
      commit( "SET_USER_MENU_LIST_BY_ROLE", menuListByRole );
    } );
  },

  async roleMenuInfo( { commit } ) {

    let roleMenuInfo = await $nuxt.$portalUtil.portalRoleMenuUtils.getRoleMenuInfo();

    commit( "SET_ROLE_MENU_INFO", roleMenuInfo );
  },

  roleMenuInfoBySearch( { commit }, url ) {

    let roleMenuInfoBySearch = $nuxt.$portalUtil.portalRoleMenuUtils.getRoleMenuInfoBySearch( url );

    // console.log('### roleMenuInfoBySearch ',  roleMenuInfoBySearch )

    commit( "SET_ROLE_MENU_INFO", roleMenuInfoBySearch );
  },
}

export const getters = {

  beforeLoginUserMenuListByRole( state ) {
    if ( $nuxt.$validate.isEmpty( state.beforeLoginUserMenuListByRole ) && $nuxt.$validate.isEmpty( $nuxt.$store.state.common.login.loginUser.userRoleOids ) ) {
      $nuxt.$store.dispatch( "portal/menuRole/getBeforeLoginUserMenuListByRole" );
    }

    return state.beforeLoginUserMenuListByRole;
  },

  userMenuListByRole( state ) {
    if ( $nuxt.$validate.isEmpty( state.userMenuListByRole ) && $nuxt.$validate.isNotEmpty( $nuxt.$store.state.common.login.loginUser.userRoleOids ) ) {
      $nuxt.$store.dispatch( "portal/menuRole/getUserMenuListByRole" );
    }

    return state.userMenuListByRole;
  },

  adminMenuListByRole( state ) {

    if ( $nuxt.$validate.isEmpty( state.adminMenuListByRole ) && $nuxt.$validate.isNotEmpty( $nuxt.$store.state.common.login.loginUser.userRoleOids ) ) {
      $nuxt.$store.dispatch( "portal/menuRole/getAdminMenuListByRole" );
    }

    return state.adminMenuListByRole;
  },

  /**
   * 등록권한 여부
   * - 1:1문의 게시판은 로그인 후에만 등록 가능
   */
  hasWriteRole( state ) {
    // console.log('!!!!!!!!!!!!1!inputYn', state.roleMenuInfo.inputYn )

    if ( $nuxt.$validate.isEmpty( state.roleMenuInfo ) ) {
      return false;
    }

    // 로그인 유저
    const loginUser = $nuxt.$store.state.common.login.loginUser;

    // 게시판이 1:1 문의 게시판일때 등록은 로그인 후에만 가능
    if( $nuxt.$constant.BOARD_OID.ONE_TO_ONE_INQUIRY.OID === $nuxt.$route.query.menuOid && $nuxt.$validate.isNotEmpty( loginUser.userId ) ) {
      return $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.menuManagementYn || $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.inputYn;
    }
    else {
      return $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.menuManagementYn || $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.inputYn;
    }
  },

  /**
   * 상세보기권한 여부
   *
   */
  hasReadRole( state ) {

    if ( $nuxt.$validate.isEmpty( state.roleMenuInfo ) ) {
      return false;
    }

    // console.log('!!!!!!!!!!!2!!readYn ', state.roleMenuInfo )
    // console.log('!!!!!!!!!!!2!!readYn ', state.roleMenuInfo.readYn )

    return $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.menuManagementYn || $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.readYn;
  },

  /**
   * 수정권한 여부
   *
   */
  hasModifyRole( state ) {

    if ( $nuxt.$validate.isEmpty( state.roleMenuInfo ) ) {
      return false;
    }

    // console.log('!!!!!!!!!!!3 modYn !!', state.roleMenuInfo.modYn )

    return $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.menuManagementYn || $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.modYn;
  },

  /**
   * 삭제권한 여부
   *
   */
  hasDeleteRole ( state ) {

    if ( $nuxt.$validate.isEmpty( state.roleMenuInfo ) ) {
      return false;
    }

    // console.log('!!!!!!!!!!!!4!delYn', state.roleMenuInfo.delYn )

    return $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.menuManagementYn || $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.delYn;
  },

  /**
   * 댓글권한 여부
   *
   */
  hasRecommendRole( state ) {

    if ( $nuxt.$validate.isEmpty( state.roleMenuInfo ) ) {
      return false;
    }

    // console.log('!!!!!!!!!!!!5!recommendYn', state.roleMenuInfo.recommendYn )

    return $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.menuManagementYn || $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.recommendYn;
  },

  /**
   * 답글권한 여부
   *
   */
  hasReplyRole( state ) {

    if ( $nuxt.$validate.isEmpty( state.roleMenuInfo ) ) {
      return false;
    }

    // console.log('!!!!!!!!!!!!!6replyYn', state.roleMenuInfo.replyYn )

    return $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.menuManagementYn || $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.replyYn;
  },

  /**
   *  비밀글권한 여부
   */
  hasSecretRole( state ) {

    if ( $nuxt.$validate.isEmpty( state.roleMenuInfo ) ) {
      return false;
    }

    // console.log('!!!!!!!!!!!!!6replyYn', state.roleMenuInfo.secretUseYn )

    return $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.menuManagementYn || $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.secretUseYn;
  },

  /**
   * 관리자 권한
   *
   */
  hasAdminRole( state ) {

    if ( $nuxt.$validate.isEmpty( state.roleMenuInfo ) ) {
      return false;
    }

    // console.log('!!!!!!!!!!7!!menuManagementYn!', state.roleMenuInfo.menuManagementYn )

    return $nuxt.$constant.FLAG_YN.YES === state.roleMenuInfo.menuManagementYn;
  }
}
