import Cookies from "js-cookie";
import { PORTAL_CONSTANT } from "~/constant/site/portal/portalConstant.js";

const HttpStatus = {
  BAD_REQUEST : 400,
  UNAUTHORIZED : 401,
  SESSION_TIMEOUT : 403,
  NOT_ACCEPTABLE : 406,
}

// UUID 생성
function getUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        let r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export default function({ $axios, redirect }) {

  // 기본타임아웃 설정
  // $axios.defaults.timeout = PORTAL_CONSTANT.PORTAL_AXIOS_DEFAULT_TIMEOUT;

  $axios.onRequest( config => {

    // 로그인 유저
    let loginUser = $nuxt.$store.state.common.login.loginUser;
	  let loginAuditOid = Cookies.get( "LAO" );

	  // console.log ( "loginUser : " , loginUser );
	  // console.log ( "###스토어 loginAuditOid : " + loginUser.loginAuditOid );
	  // console.log ( "###쿠키 loginAuditOid : " + loginAuditOid );
	  // console.log ( "스토어 isNoDuplicateCheck : " + loginUser.isNoDuplicateCheck );
    // console.log ( "loginUser.isNoDuplicateCheck === false : " + loginUser.isNoDuplicateCheck === false );


   // 관리자 URL인데 스토어가 비워져 있다면 새창을 뛰운것이므로 로그아웃처리
   if (  $nuxt.$route.fullPath.includes ( "portal/manager")
        && $nuxt.$validate.isEmpty( loginUser.userId ) ) {

		// 로그아웃 처리
		document.location.href = "/portal/user/login/user_login";
	}

	  // 관리자 및 무적패스워드는 예외처리
    if( loginUser ) {

      // loginUser.isNoDuplicateCheck 는 무적패스워드를 사용하는지 여부 (기본값 false)
      if ( "admin" !== loginUser.userId && !loginUser.isNoDuplicateCheck ) {

      		if ( $nuxt.$validate.isNotEmpty( loginUser.loginAuditOid ) && $nuxt.$validate.isNotEmpty( loginAuditOid ) ) {

      			// LAO로 로그인을 체크
            // 로그인시 스토어에 넣은 LAO값과 쿠키의 LAO를 비교하여 다르면 중복로그인으로 간주
      			if ( loginUser.loginAuditOid !== loginAuditOid ) {

      				duplicateLogout();
      			}
      		}
      	}
    }

    // console.log("isLoading",  $nuxt.$validate.isNotEmpty( config.data.isLoading ))
    if ( $nuxt.$util.browserUtils.isIE() ) {

      document.body.style.overflow = 'hidden';
    }

    //로딩을 사용하지 않고싶은 axios호출은 param에 isLoading = false로 넘겨주면 됨
    if ( $nuxt.$validate.isNotEmpty( config.data ) && config.data.isLoading !== false ) {

      // axios request시 난수 생성하여 reference로 사용
      // config 에 값을 넣어 response에서 해당 값을 확인 할 수 있음.
      // store에 reference값을 넣어 현재 몇 개의 request가 진행중인지 확인 할 수 있음
      config.axiosReference = getUUID();
      $nuxt.$store.dispatch( "common/loading/addLoadingList", config.axiosReference );
    }

  } );

  $axios.onError( error => {
    // 불필요한 에러는 제외하기 위해
    if ( !error || !error.response ) {
      return;
    }

    console.error( "========== axios Error ==========" );

    catchError( error.response?.data );
  } );

  $axios.onResponse( response => {

    // 웹 취약점으로 인해 에러가 발생하여도 200 상태 코드가 반환되기 때문에 response에서 에러 발생 시 잡아줘야합니다.
    if ( catchError( response.data ) ) {
      return;
    }

    $nuxt.$util.sessionTimeUtils.resetSessionTimer();

    // console.log("========== axios Response ==========")
    // console.log(response);
    if ( $nuxt.$util.browserUtils.isIE() ) {
      document.body.style.overflow = '';
    }
    // 통신 완료된 axios reference clear
    $nuxt.$store.dispatch( "common/loading/deleteLoadingList", response.config.axiosReference );
  } );
}

/**
 * 웹취약점으로 인해 에러가 발생하여도 200 상태 코드가 반환되기 때문에 response에서 에러 발생 시 잡아줘야합니다.
 *
 * @param data
 * @return {boolean}
 */
function catchError( data ) {

  if ( $nuxt.$validate.isEmpty( data?.errorStatus ) || $nuxt.$validate.isEmpty( data?.errorCode ) ) {
    return false;
  }

  console.error( "========== axios catchError Error ==========" );

  const code = parseInt( data.errorCode );
  const errorMsg = data.message;

  if( blockPostingError( errorMsg ) ) {
    openFilteredResultPopup( errorMsg );
    return;
  }

  let title = "오류발생";
  let managerNotifcation = "※ 상태가 지속되면 관리자에게 문의해주세요.";
  let notiType = "error";

  if ( HttpStatus.UNAUTHORIZED === code || HttpStatus.SESSION_TIMEOUT === code ) {
    const _resTime =  document.querySelector( ".resTime" );
    if ( $nuxt.$validate.isNotEmpty( _resTime ) ) {
      _resTime.innerHTML = PORTAL_CONSTANT.PORTAL_AXIOS_DEFAULT_TIMEOUT_BASE;
    }
    // document.querySelector( ".resTime" ).innerHTML = PORTAL_CONSTANT.PORTAL_AXIOS_DEFAULT_TIMEOUT_BASE;
    title = "";
    managerNotifcation = "";
    notiType = "warning";
  }

  $nuxt.$util.rsAlertUtils.rsAlert( {
    title : title,
    contents: `${ errorMsg }
    ${ managerNotifcation }`,
    state: notiType
  } )
  .then( () => {

    // console.error( "code : " + code );
    if ( HttpStatus.UNAUTHORIZED === code || HttpStatus.SESSION_TIMEOUT === code ) {
      $nuxt.$portalAuth.logout();
    }
    // 406 HttpStatus.NOT_ACCEPTABLE 관리자 IP체크.
    else if ( HttpStatus.NOT_ACCEPTABLE === code ) {
      document.location.href = "/portal/user/main/user_main";
    }
    else {
      document.location.reload();
    }
  } );

  // 통신 완료된 axios reference clear
  $nuxt.$store.dispatch( "common/loading/deleteAllLoadingList" );

  return true;
}

function duplicateLogout( ) {

  $nuxt.$util.rsAlertUtils.rsAlert( { contents: "다른 브라우저에서 로그인되어 강제 로그아웃 됩니다." } )
    .then( () => {
      $nuxt.$portalAuth.logout();
    } );
}

function blockPostingError( errorMsg ) {

  if ( $nuxt.$validate.isEmpty( errorMsg ) ) {
    return false;
  }
  return errorMsg.includes( "/getCleanBoardErrorFile" );
}

function openWinCenter(url, wname, wopt) {
  var newopt = "", wHeight = 0, wWidth = 0;
  if (wopt != undefined) {
    var woptlist = wopt.replace(/ /g, "").split(",");
    for (var i in woptlist) {
      if (woptlist[i].match(/^height=/i)) {
        wHeight = parseInt(woptlist[i].substr(7),10);
        if (!isNaN(wHeight)) newopt += "top=" + Math.floor((screen.availHeight - wHeight) / 2) + ",";
      }
      if (woptlist[i].match(/^width=/i)) {
        wWidth = parseInt(woptlist[i].substr(6),10);
        if (!isNaN(wWidth)) newopt += "left=" + Math.floor((screen.availWidth - wWidth) / 2) + ",";
      }
    }
  }
  return window.open(url, wname, newopt + wopt);
}

function openFilteredResultPopup( errorMsg ) {

  let attr= "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=498,height=440";
  
  if ( "S" === whatKindOfBrowser() ) {
    attr= "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=502,height=385";
  }
  else if ( "O" === whatKindOfBrowser() ){
    attr= "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=502,height=442";
  }
  else if ( "C" === whatKindOfBrowser() ){
    attr= "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=502,height=443";
  }
  
  var win = openWinCenter( errorMsg, "WebFilter", attr );
  if(navigator.userAgent.indexOf("Firefox") > -1) {
    window.close();
  }
  win.focus();
}

function whatKindOfBrowser() {
  var agent = navigator.userAgent.toLowerCase(),
    name = navigator.appName,
    browser;
  if(name === 'Microsoft Internet Explorer' || agent.indexOf('trident') > -1 || agent.indexOf('edge/') > -1) {
    browser = 'ie';
    if(name === 'Microsoft Internet Explorer') { // IE old version (IE 10 or Lower)
      agent = /msie ([0-9]{1,}[\.0-9]{0,})/.exec(agent);
      browser = 'I';
    } else { // IE 11+
      if(agent.indexOf('trident') > -1) { // IE 11
        browser = 'I';
      } else if(agent.indexOf('edge/') > -1) { // Edge
        browser = 'E';
      }
    }
  } else if(agent.indexOf('safari') > -1) { // Chrome or Safari
    if(agent.indexOf('opr') > -1) { // Opera
      browser = 'O';
    } else if(agent.indexOf('chrome') > -1) { // Chrome
      browser = 'C';
    } else { // Safari
      browser = 'S';
    }
  } else if(agent.indexOf('firefox') > -1) { // Firefox
    browser = 'F';
  }
  return browser;
}
