export const state = () => ( {

  menuList  : [],
  adminFavList : [],
} )

export const mutations = {

  SET_MENU_LIST( state, menuList ) {
    state.menuList = menuList;
  },
  SET_ADMIN_FAV_LIST( state, favList ) {
    state.adminFavList = favList;
  },
}

export const actions = {

  // 사용자 or 관리자의 전체 메뉴를 가져옵니다.
  async getMenuList( { commit }, paramObj ) {

    const URL_MENU_LIST = "/api/manager/v1/portalManagerMenuApi_listMenuTree";

    // class 타입 + 즐겨찾기 정보 채워옴
    let param = {
      // favoriteSearch: true,
      className : paramObj.className,
      useYn     : paramObj.useYn,
      fillBoard : true,
    };

    await $nuxt.$axios.post( URL_MENU_LIST, param ).then( res => {

      if ( $nuxt.$validate.isEmpty( res.data ) ) {
        return;
      }

      commit( "SET_MENU_LIST", res.data );
    } );
  },

  async getAdminFavList( { commit } ) {
    const URL_FAV_LIST = "/api/manager/v1/portalManagerOperateApi_listAllFavorite";

    let param = {};
    param.inputUser = $nuxt.$store.state.common.login.loginUser.userOid;
    param.targetObject = $nuxt.$constant.MENU_OBJECT_TYPE;

    if ( $nuxt.$validate.isEmpty( param.inputUser ) ) {
      return;
    }

    await $nuxt.$axios.post( URL_FAV_LIST, param ).then( res => {
      if ( $nuxt.$validate.isEmpty( res.data ) ) {
        return;
      }

      commit( "SET_ADMIN_FAV_LIST", res.data );
    } )
  }
}

export const getters = {

  menuList : ( state ) => ( className, useYn ) => {

    if ( $nuxt.$validate.isEmpty( state.menuList ) ) {
      $nuxt.$store.dispatch( "portal/menu/getMenuList", { className :className, useYn : useYn } );
    }
    return state.menuList;
  },

  adminFavMenuList( state ) {
	  
    if ( $nuxt.$validate.isEmpty( state.adminMenuListByRole ) && $nuxt.$validate.isNotEmpty( $nuxt.$store.state.common.login.loginUser.userRoleOids ) ) {
      $nuxt.$store.dispatch( "portal/menuRole/getAdminMenuListByRole" );
      return;
    }
    if ( $nuxt.$validate.isEmpty( state.adminFavList ) ) {
      $nuxt.$store.dispatch( "portal/menu/getAdminFavList" );
      return;
    }

    let favMenuList = _.cloneDeep( state.adminFavList );

    favMenuList.forEach( fav => {
		
      state.adminMenuListByRole.forEach( menu => {
        if ( $nuxt.$validate.isNotEmpty( menu.childTreeNodeList ) ) {
          let child = menu.childTreeNodeList.find( menu => menu.oid === fav.targetOid );

          if( $nuxt.$validate.isNotEmpty( child ) ) {
            fav.menuInfo = child;
            fav.urlFullPath = menu.url + child.url;
            fav.parentName = menu.name;
            fav.name = child.name;
          }
        }
      } )
      
    } )

    return favMenuList;
  },
}
