import Cookie from "js-cookie";
import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';

/**
 * 세션 타임 유틸
 */
const sessionTimeUtils = (() => {

  const { isEmpty } = typeValidationUtils;

  // 자릿수 변환
  function pad( n, width ) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n
  }

  /**
   * 시간 형식으로 변환 리턴
   *
   * @param {number} sessionTime
   * @return {string}
   */
  function prettyTime( sessionTime ) {
    const hour = Math.floor( sessionTime / 3600 ); // 1 = 3600 / 3600
    const minutes = Math.floor(( sessionTime - hour * 3600 ) / 60); // 0 = 3600 - 1 * 3600
    const seconds = sessionTime % 60; // 0 = 3600 % 60

    return pad( hour, 2 ) + ":" + pad( minutes, 2 ) + ":" + pad( seconds, 2 )
  }

  return {

    SESSION_TIME : {
      FULL_TIME : 0,
      FIX_FULL_TIME : 0,
      TIMER_KEY : "timer",
    },


    // 타이머 리셋
    resetSessionTimer() {
      this.SESSION_TIME.FULL_TIME = this.SESSION_TIME.FIX_FULL_TIME;
    },

    /**
     * ENV_CONFIG 공통 데이터 조회
     * */
    getListAllEnvConfigCommon(){

      // 환경설정이 아닌 쿠키에 가져온 값으로 세션타임아웃 설정
      const est = parseInt( Cookie.get( "EST" ) ) ?? 0;
      const lst = parseInt( Cookie.get( "LST" ) ) ?? 0;

      const remainSessionTime = ( est - lst ) / 1000;

      this.SESSION_TIME.FULL_TIME = remainSessionTime;
      this.SESSION_TIME.FIX_FULL_TIME = remainSessionTime;
    },

    /**
     * 남은 세션 시간을 반환합니다.
     *
     * @return {number|string}
     */
    getSessionRestTime() {

      // 세션 시간이 없을 경우 세션 시간을 재설정합니다.
      if ( isEmpty( this.SESSION_TIME.FULL_TIME ) ) {
        this.getListAllEnvConfigCommon();
      }

      this.SESSION_TIME.FULL_TIME--;

      // 세션 시간이 남아있지 않다면 0을 리턴합니다.
      return 0 >= this.SESSION_TIME.FULL_TIME ? 0 : prettyTime( this.SESSION_TIME.FULL_TIME );
    },

  }
})();

export default sessionTimeUtils;