import visitUtils from "~/plugins/core/utils/ui/audit/visitUtils";
import tagUtils from "~/plugins/core/utils/ui/board/tagUtils";
import postingUtils from '~/plugins/core/utils/ui/board/postingUtils';
import messageUtils from '~/plugins/core/utils/ui/notification/messageUtils';
import fileUtils from '~/plugins/core/utils/ui/storage/fileUtils';
import userUtils from '~/plugins/core/utils/ui/org/userUtils';
import eventUiUtils from '~/plugins/core/utils/ui/eventUiUtils';
import sessionTimeUtils from '~/plugins/core/utils/ui/sessionTimeUtils';
import timerUtils from '~/plugins/core/utils/ui/timerUtils';

/**
 * UI 유틸 모음
 *
 */
const uiUtils = {

  // audit > 방문자 및 로그인 이력관련
  visitUtils,

  // Board >  태그 관련
  tagUtils,

  // Board > 게시글 관련
  postingUtils,

  // notification > 메세지 관련
  messageUtils,

  // org > 사용자 관련
  userUtils,

  // storage > 파일 관련
  fileUtils,

  /**
   * ui 관련
   *
   */

  // 세션 타임 유틸
  sessionTimeUtils,

  // 타이머 유틸
  timerUtils,

  // ui event 관련
  eventUiUtils,

}

export default uiUtils;