import serviceUtils from '~/plugins/core/utils/service/serviceUtils';
import uiUtils from '~/plugins/core/utils/ui/uiUtils';
import dateUtils from '~/plugins/core/utils/dateUtils';
import rsAlertUtils from '~/plugins/core/utils/rsAlertUtils';
import formatUtils from '~/plugins/core/utils/formatUtils';
import encryptUtils from '~/plugins/core/utils/encryptUtils';
import copyUtils from '~/plugins/core/utils/copyUtils';
import browserUtils from '~/plugins/core/utils/browserUtils';
import excelUtils from '~/plugins/core/utils/excelUtils';


const utils = {

  // 날짜 관련
  dateUtils,

  // alert 관련
  rsAlertUtils,

  // 포맷 관련
  formatUtils,

  // 암호화 관련
  encryptUtils,

  // 복사 관련
  copyUtils,

  // 브라우저 관련
  browserUtils,

  // 엑셀 관련
  excelUtils,

  // Service 유틸 관련
  ...serviceUtils,

  // UI 유틸 관련
  ...uiUtils,

}

/**
* 사용방법
* this.$util.visitUtils.getUserVisitInfo();
*
* 만약 $util에서 전부 다 부르고 싶으면
* 위의 visitUtils을 스프레드 연산자로 넣어야 함( ...visitUtils )
* 그렇게 하지 않는 이유는 utils마다 각각의 유틸객체를 알고 쓰는것이 낫기 때문에.
*
*/
export default ( context, inject ) => {
	inject( "util", utils );
};

