import SearchCnd from "~/models/site/portal/searchCnd"

export const state = () => ( {

	/**
	 *  TheSearch 의 상태와 TheSpaceFilter 의 상태를 관리합니다.
	 *
	 *  목록 화면에서 상세 화면 ( 또는 이어서 수정화면 ) 으로 진입한 뒤,
	 *  취소 버튼이나 뒤로가기, 목록 버튼을 통해
	 *  목록화면으로 돌아가는 경우 검색 조건을 TheSearch 와 TheSpaceFilter 에
	 *  렌더링해서 상세화면으로 진입하기 전의 검색조건을 반영하기 위함.
	 */

	searchCnd : {},

} );

export const mutations = {

	SET_SEARCH_CND ( state, payload ) {
		state.searchCnd = payload;
	}
}

export const actions = {

	setSearchCnd( { commit }, payload ) {

		let cnd = new SearchCnd();
		cnd.setSearchCnd( payload );
		commit( "SET_SEARCH_CND", cnd );
	}

}

export const getters = {

	/**
	 * 이전 route 정보를 가져와서 검색조건을 초기화합니다.
	 *
	 * 대신 같은 종류의 관리자 게시판일 경우에만 남기고,
	 * 아닐 경우에는 검색조건을 빈 값으로 초기화합니다.
	 *
	 * 같은 관리 범주 ( 예약현황 페이지 <-> 예약상세 페이지 : 같은 범주 ) 임을 확인하는 것은 route.path 를 쪼개서 확인하는 방식으로 진행합니다.
	 */
	getSearchCnd( state ) {

		// 이전 route path ( ex) '/mathSciences/manager/reserveMgmt/approval/approval_list' )
		let prevContext = $nuxt.context.from;

		// 이전 route 의 query
		let prevQuery = prevContext.query;
		// console.log( "이전 query ",prevQuery );

		// 현재 route path
		let currentContext = $nuxt.$route;

		// 현재 route 의 query
		let currentQuery = currentContext.query;
		// console.log( '현재 query : ', currentQuery );

		// 이걸 '/' 로 자른 배열을 만든다.
		let prevSplited = prevContext.path.split('/');
		let currentSplited = currentContext.path.split('/');

    // console.log( 'currentSplited :', currentSplited );
    // console.log( 'prevSplited :', prevSplited );
		// 이 배열이 서로 몇개가 같은지를 확인하여 searchFilter의 검색조건을 초기화할 것인지 유지할 것인지를 결정

		// 같은 것이 5개 => 다른 관리 목록 => 검색 조건 초기화
		// 같은 것이 6개 이상 => 같은 관리 목록이며 이전에 상세 또는 수정화면인 경우 ( view <=> list, edit <=> list ) => 검색 조건 유지
		let matchedCount =  prevSplited.filter( i => currentSplited.includes( i ) ).length;
		// console.log('######## 같은 것이 ' + matchedCount + '개');

		/**
		 * matchedCount 가 6개 이상이면 같은 관리 객체로 본다.
		 * 또 탭을 이동하는 경우에도 active 값이 같은지 확인한다.
		 * ( 관리자 화면에서는 우리는 탭 구분을 query 의 'active' 라는 이름으로 주고 있기 때문에 )
		 */
		if ( 6 <= matchedCount && prevQuery.active === currentQuery.active ) {
			// 같은 것이 6개 이상, 검색 조건을 유지
			// console.log(' 같은 것이 6개 이상, 검색조건 유지' );
			return state.searchCnd;

		}
		else {
			// console.log('같은 값이 6개 미만,,,검색조건 초기화.....');
			return new SearchCnd();
		}
	},

}
