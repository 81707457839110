import formValidationUtils from '~/plugins/core/utils/validation/formValidationUtils';
import typeValidationUtils from '~/plugins/core/utils/validation/typeValidationUtils';

/**
 * Validation 유틸 모음
 *
 */
const validate = {

  // form validation 유틸
  ...formValidationUtils,

  // type validation 유틸
  ...typeValidationUtils,

}

/**
 * 해당 유틸은 plugins/utils/core/validation 패키지에 있는 파일을 호출합니다.
 *
 * 해당 유틸의 경우 $utils 가 아닌 $validate 으로 호출하여 사용합니다.
 * $util.typeValidationUtils(X) -> $validate.isEmpty(O)
 *
 * @param context
 * @param inject
 */
export default ( context, inject ) => {
  inject( "validate", validate );
}