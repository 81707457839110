import Cookies from "js-cookie";

/**
 * 클라이언트 세션 공통 메소드 처리를 위한 플러그인 인터페이스
 *
 * */
class SessionStrategy {
	
	
	YEK = "UkVtYVJrYUJsRVNvRnRAQA==";			//	암호화 키

    /**
     * 세션 체크의 대상이 되는 쿠키
     * 로그인 시 변경되는 쿠키여야 함 ) LUI, LUO, UAC...
     *
     * @protected
     * @type {string}
     * */
    _checkTargetCookie;

    /**
     * 영속성 유지 Storage 지정
     *
     * @protected
     * @type {'sessionStorage'|'localStorage'|'cookie'}
     * */
    _persistenceStrategy = "sessionStorage";

    /**
     * Persistence 설정 > Store가 비었을 때 이 아이템을 전달해서 아이템을 조회합니다.
     *
     * @public
     * @param {string} key - Storage / Cookie에서 조회가 필요한 아이템
     * @return {Object|string} 영속성을 유지할 스토어의 값에 따라서 반환값이 달라짐
     * */
    getItem( key ) { return ""; }

    /**
     * Persistence 설정 > 지정된 Store 값을 setItem 로직에 따라 영속성을 부여합니다.
     *
     * @public
     * @param {string} key   - Storage / Cookie에서 조회가 필요한 아이템`
     * @param {string} value - key의 값
     * @return {void}
     * */
    setItem( key , value ) { throw new Error("미구현"); }

    /**
     * Persistence 설정 > 지정된 값을 삭제합니다.
     *
     * @public
     * @return {void}
     * */
    removeItem( key ) { throw new Error("미구현"); }

    /**
     * 로그인이 다른 탭에서 살아있는지 판별합니다.
     *
     * @return {Boolean} true라면 로그인 세션 살아있는 것
     * */
    isSessionAlive()  { throw new Error("미구현"); }

    /**
     * 영속성 키를 반환합니다.
     *
     * @return {String}
     * */
    getPersistenceKey() { return ""; }

    /**
     * 세션 Encrypt
     *
     * @protected
     * @param {string} value - 암호화 값
     * @return {void}
     * */
    _encryptSession( value ) { throw new Error("미구현"); }

    /**
     * 세션 Encrypt
     *
     * @protected
     * @param {string} value - 복호화할 값
     * @return {String} 복호화된 값
     * */
    _decryptSession( value ) { throw new Error("미구현"); }

}

/**
 * 기존 영속성 설정 > 세션 스토리지, 탭 간 클라이언트 세션 공유 불가
 *
 * */
class DefaultStrategy {

    /**
     * 영속성 유지 Storage 지정
     *
     * @protected
     * @type {'sessionStorage'|'localStorage'|'cookie'}
     * */
    _persistenceStrategy = "sessionStorage";

    /**
     * Persistence 설정 > Store가 비었을 때 이 아이템을 전달해서 아이템을 조회합니다.
     *
     * @public
     * @param {string} key - Storage / Cookie에서 조회가 필요한 아이템
     * @return {Object|string} 영속성을 유지할 스토어의 값에 따라서 반환값이 달라짐
     * */
    getItem( key ) {
        window.sessionStorage.getItem( key );
    }

    /**
     * Persistence 설정 > 지정된 Store 값을 setItem 로직에 따라 영속성을 부여합니다.
     *
     * @public
     * @param {string} key   - Storage / Cookie에서 조회가 필요한 아이템`
     * @param {string} value - key의 값
     * @return {void}
     * */
    setItem( key , value ) {

        window.sessionStorage.setItem( key , value );
    }

    /**
     * Persistence 설정 > 지정된 값을 삭제합니다.
     *
     * @public
     * @return {void}
     * */
    removeItem( key ) {

        window.sessionStorage.removeItem( key );
    }

    /**
     * 로그인이 다른 탭에서 살아있는지 판별합니다.
     *
     * @return {Boolean} store
     * */
    isSessionAlive()  { return false; }

    /**
     * 영속성 키를 반환합니다.
     *
     * @return {String}
     * */
    getPersistenceKey() { return "NSI"; }
}

/**
 * 클라이언트 세션 전략 > 다중탭 세션 공유 정책 구현
 *
 * */
class CookieStrategy extends SessionStrategy {

    /**
     * Store의 영속성이 유지되는 대상 Key
     *
     * @type {String}
     * */
    persistenceTargetKey = "NSI";

    /**
     * 세션 체크의 대상이 되는 쿠키
     * 로그인 시 변경되는 쿠키여야 함 ) LUI, LUO, UAC...
     *
     * @protected
     * */
    _checkTargetCookie = "LUO";


    _persistenceStrategy = "cookie";

    /**
     * Nuxt Context
     *
     * @type {$common : Object,store : Object}
     * */
    #context;

    constructor( context ) {
        super();
        this.#context = context;
    }

    /**
     * Persistence 설정 > Store가 비었을 때 이 아이템을 전달해서 아이템을 조회합니다.
     *
     * @public
     * @param {string} key - Storage / Cookie에서 조회가 필요한 아이템
     * @return {Object|string} 영속성을 유지할 스토어의 값에 따라서 반환값이 달라짐
     * */
    getItem( key ) {

        if ( "@@" === key ) {
            return "";
        }

        const encryptedValue = Cookies.get(key);

        if ( this.#context.$validate.isEmpty( encryptedValue ) ) {
            return "";
        }

        return JSON.parse( this._decryptSession( encryptedValue ) )
    }

    /**
     * Persistence 설정 > 지정된 Store 값을 setItem 로직에 따라 영속성을 부여합니다.
     *
     * @public
     * @param {string} key   - Storage / Cookie에서 조회가 필요한 아이템`
     * @param {string} value - key의 값
     * @return {void}
     * */
    setItem( key, value ) {

        if ( "@@" === key ) {
            return;
        }

       // const loginUserInfo = this.#context.store.state.common.login.loginUser;
        // const targetValue = this._decryptSession( Cookies.get( this._checkTargetCookie ) );

        if ( key !== this.persistenceTargetKey ) {
            return;
        }

//        if ( this.#context.$validate.isEmpty( targetValue ) ) {
//            return;
//        }

        Cookies.set( key , this._encryptSession( value ) );
    }

    /**
     * Persistence 설정 > 지정된 값을 삭제합니다.
     *
     * @public
     * @return {void}
     * */
    removeItem( key ) {

        Cookies.remove( key );
    }

    /**
     * 로그인이 다른 탭에서 살아있는지 판별합니다.
     *
     * @return {Boolean} true라면 다른 탭 세션이 살아있는 것
     * */
    isSessionAlive() {

        const sessionOid = this.#context.store.state.common.login.loginUser.userOid;
        const targetValue = this._decryptSession( Cookies.get( this._checkTargetCookie ) );

        // 1. userOid가 비었으면 세션 죽은 것을 판단
        if ( this.#context.$validate.isEmpty( sessionOid ) ) {
            return false;
        }

        // 2. MAIN 화면 일 경우 그대로 진행
        // 메인일 경우 그대로 진행
        if ( document.location.href.includes( process.env.SITE_NAME ) ) {
            return false;
        }

        return sessionOid === targetValue;
    }

    /**
     * 영속성 키를 반환합니다.
     *
     * @return {String}
     * */
    getPersistenceKey() { return this.persistenceTargetKey; }

    /**
     * 세션 Encrypt
     *
     * @protected
     * @param {string} value - 암호화 값
     * @return {string}
     * */
    _encryptSession( value ) {

        return encodeURI( this.#context.$util.encryptUtils.encryptAesValueByKey( value , this.YEK ) );
    }

    /**
     * 세션 Encrypt
     *
     * @protected
     * @param {string} value - 복호화할 값
     * @return {String} 복호화된 값
     * */
    _decryptSession( value ) {

        return this.#context.$util.encryptUtils.decryptAesValueByKey( decodeURIComponent( value ) , this.YEK );
    }
}

/**
 * 세션 정책 사용
 * */
export function sessionStrategyProvider ( context , sessionStrategy ) {

    if ( "cookie" === sessionStrategy ) {
        return new CookieStrategy( context );
    }
    else if ( "sessionStorage" === sessionStrategy ) {
        return new DefaultStrategy();
    }
    else {
        return new DefaultStrategy();
    }
}
