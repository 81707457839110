import CryptoJS from 'crypto-js';

/**
 * Encrypt 유틸
 *
 */
const encryptUtils = ( () => {

  const ENCRYPT_YEK = "UkVtYVJrYUJsRVNvRnRAQA==";

  return {

    /**
     * 암호화 처리
     *
     * @param data
     * @return {string}
     */
    encryptAesValue( data ) {

      const parsedBase64Key = CryptoJS.enc.Base64.parse( ENCRYPT_YEK );

      const encryptedData = CryptoJS.AES.encrypt( data, parsedBase64Key, {
        mode    : CryptoJS.mode.ECB,
        padding : CryptoJS.pad.Pkcs7,
      } );

      return encryptedData.toString();
    },

    /**
     *
     *
     * @param data
     * @param key
     * @return {string}
     */
    encryptAesValueByKey( data , key ) {

      const parsedBase64Key = CryptoJS.enc.Base64.parse( key );

      const encryptedData = CryptoJS.AES.encrypt( data, parsedBase64Key, {
        mode    : CryptoJS.mode.ECB,
        padding : CryptoJS.pad.Pkcs7,
      } );

      return encryptedData.toString();
    },

    /**
     * 복호화 처리
     * @param data
     * @return {string}
     */
    decryptAesValue( data ) {

      if ( !data ) {
        return "";
      }

      const parsedBase64Key = CryptoJS.enc.Base64.parse( ENCRYPT_YEK );

      const decryptedData = CryptoJS.AES.decrypt( data, parsedBase64Key, {
        mode    : CryptoJS.mode.ECB,
        padding : CryptoJS.pad.Pkcs7,
      } );

      return decryptedData.toString( CryptoJS.enc.Utf8 );
    },

    decryptAesValueByKey( data , key ) {

      if ( !data ) {
        return "";
      }

      const parsedBase64Key = CryptoJS.enc.Base64.parse( key );

      const decryptedData = CryptoJS.AES.decrypt( data, parsedBase64Key, {
        mode    : CryptoJS.mode.ECB,
        padding : CryptoJS.pad.Pkcs7,
      } );

      return decryptedData.toString( CryptoJS.enc.Utf8 );
    },

  };

} )();

export default encryptUtils;