import createPersistedState from 'vuex-persistedstate';

/**
 * 참가신청 Persistence 설정
 * 탭간 세션을 유지하기 위해 sessionStorage에서 Cookie로 변경
 * 개발, 로컬 환경에서 secure cookie가 안되므로 암호화 해서 처리 : PersistedState로 관리하는 Store가 많아지면 느려질 수 있음
 *
 * @author james
 * @version 2024.06.24 
 * */
export default ({ store }) => {

  /** @type {SessionStrategy} */
  const sessionStrategy = store.$portalCommon.sessionStrategy;

  createPersistedState({
    storage: {
      getItem   : ( key )         => sessionStrategy.getItem( key ),
      setItem   : ( key , value ) => sessionStrategy.setItem(  key , value ),
      removeItem: ( key )         => sessionStrategy.removeItem( key ),
    },
    key: sessionStrategy.getPersistenceKey(),
    paths: ["common.login", "portal.iframe" ] 
  })(store)
}
