<template>
  <div class="frequent-menu" :class="isClear" @mouseleave="setMenu">
    <div class="frequent-menu__img" @click="onViewMenu()">
      <img src="@/assets/images/portal/icon/main/icon_frequent.png" alt="자주찾는서비스">
    </div>

    <!--   차주찾는서비스 탭   -->
    <ul class="frequent-menu__tabs">
      <!--   학생 탭   -->
      <li class="student"
          :class=" isType === '학생' ? 'is-active' : '' "
          @mouseover="onUserType('학생')">
        <span class="material-icons">navigate_before</span>
        <span class="text">학생</span>
      </li>

      <!--   학부모 탭   -->
      <li class="parent"
          :class=" isType === '학부모' ? 'is-active' : '' "
          @mouseover="onUserType('학부모')">
        <span class="material-icons">navigate_before</span>
        <span class="text">학부모</span>
      </li>

      <!--   일반 탭   -->
      <li class="general"
          :class=" isType === '일반' ? 'is-active' : '' "
          @mouseover="onUserType('일반')">
        <span class="material-icons">navigate_before</span>
        <span class="text">일반</span>
      </li>

      <!--   교직원 탭   -->
      <li class="staff"
          :class=" isType === '교직원' ? 'is-active' : '' "
          @mouseover="onUserType('교직원')">
        <span class="material-icons">navigate_before</span>
        <span class="text">교직원</span>
      </li>

      <!--   로그인 탭   -->
      <li v-if="$validate.isEmpty( loginUserInfo.userOid )"
          class="login"
          :class="isType === '로그인' ? 'is-active' : ''"
          @mouseover="onUserType('로그인')">
        <nuxt-link to="/portal/user/login/user_login">
          <img src="@/assets/images/portal/icon/main/icon_login.png" alt="로그인으로 이동"/>
        </nuxt-link>
      </li>

      <!--   끄기 탭   -->
      <li class="clear" @click="onHideMenu()" @mouseover="onUserType('')">
        <span class="material-icons">clear</span>
      </li>
    </ul>

    <!--   탭 메뉴   -->
    <div v-if="isType !== '' && isType !== '로그인'" class="frequent-menu__overlay"
         :class="overlayStyle">
      <!--   학생   -->
      <ul v-if="isType === '학생'">
        <li
          v-for="( item, index ) in studentList "
          :key="index"
        >
          <div @click="goLink( item )">
            <span v-text="item.title"></span>
            <span class="point-txt" v-text="item.subTitle"></span>
          </div>
        </li>
      </ul>

      <!--   학부모   -->
      <ul v-if="isType === '학부모'">
        <li
          v-for="( item, index ) in parentList "
          :key="index"
        >
          <div @click="goLink( item )">
            <span v-text="item.title"></span>
            <span class="point-txt" v-text="item.subTitle"></span>
          </div>
        </li>
      </ul>

      <!--   일반   -->
      <ul v-if="isType === '일반'">
        <li
          v-for="( item, index ) in generalList "
          :key="index"
        >
          <div @click="goLink( item )">
            <span v-text="item.title"></span>
            <span class="point-txt" v-text="item.subTitle"></span>
          </div>
        </li>
      </ul>

      <!--   교직원   -->
      <ul v-if="isType === '교직원'">
        <li
          v-for="( item, index ) in staffList"
          :key="index"
        >
          <div @click="goLink( item )">
            <span v-text="item.title"></span>
            <span class="point-txt" v-text="item.subTitle"></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

const URL_MATH = process.env.SYSTEM.MATH.URL;
const URL_SURVEY = process.env.SYSTEM.SURVEY.URL;
const URL_PROTUTOR = process.env.SYSTEM.PROTUTOR.URL;
const URL_APPLY = process.env.SYSTEM.APPLY.URL;
const URL_CONTEST = process.env.SYSTEM.CONTEST.URL;
const URL_EBOOK = process.env.SYSTEM.EBOOK.URL;
const URL_NOTE = process.env.SYSTEM.NOTE.URL;
const URL_LMS = process.env.SYSTEM.LMS.URL;

const SYSTEM_TYPE = "S"; // 다른 시스템
const HOMEPAGE_TYPE = "H"; // 포털 홈페이지

export default {
  name: "TheFrequentMenu",
  data() {
    return {
      isClear: "",
      isType: "",

      studentList: [
        {
          title: "e학습터",
          subTitle: "온라인 수업 듣기",
          href: "https://cls3.edunet.net/cyber/cm/mcom/pmco000b00.do",
          type: SYSTEM_TYPE,
        },
        {
          title: "설문 참여",
          subTitle: "안내된 설문에 참여",
          href: `${URL_SURVEY}/survey/sso/sso_answer_login?location=/survey/list/surveyAnswerList`,
          type: SYSTEM_TYPE,
        },
        {
          title: "수리과학정보 체험센터",
          subTitle: "체험센터 신청",
          href: `${URL_MATH}/math/sso/sso_login/`,
          type: SYSTEM_TYPE,
        },
        {
          title: "온라인 참가 신청",
          subTitle: "안내된 행사 등에 참가 신청",
          href: `${URL_APPLY}/apply/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "통합도서관시스템",
          subTitle: "전자 도서관 이용",
          href: "https://lib.gwe.go.kr/",
          type: SYSTEM_TYPE,
        },
        {
          title: "독서로",
          subTitle: "온라인 독후 활동",
          href: "https://read365.edunet.net/",
          type: SYSTEM_TYPE,
        },
        {
          title: "정보영재교육원",
          subTitle: "영재교육원 알아보기",
          href: this.$constant.MENU_URL.CENTER_GENIUS,
          type: HOMEPAGE_TYPE,
        },
      ],
      parentList: [
        {
          title: "설문참여",
          subTitle: "안내된 설문에 참여",
          href: `${URL_SURVEY}/survey/sso/sso_answer_login?location=/survey/list/surveyAnswerList`,
          type: SYSTEM_TYPE,
        },
        {
          title: "온라인 참가 신청",
          subTitle: "안내된 행사 등에 참가 신청",
          href: `${URL_APPLY}/apply/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "수리과학정보 체험센터",
          subTitle: "체험센터 예약",
          href: `${URL_MATH}/math/sso/sso_login/`,
          type: SYSTEM_TYPE,
        },
        {
          title: "통합도서관시스템",
          subTitle: "전자 도서관 이용",
          href: "https://lib.gwe.go.kr/",
          type: SYSTEM_TYPE,
        },
        {
          title: "독서로",
          subTitle: "온라인 독후활동",
          href: "https://read365.edunet.net/",
          type: SYSTEM_TYPE,
        },
        {
          title: "우리원 조직 안내",
          subTitle: "조직 정보 안내",
          href: `/portal/user/centerGuide/organization/organization_info`,
          type: HOMEPAGE_TYPE,
        },
      ],
      generalList: [
        {
          title: "공지사항",
          subTitle: "우리원 공지사항 안내",
          href: `/portal/user/common/commonBoard_list?menuOid=1SqydbhO000`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "설문 참여",
          subTitle: "안내된 설문에 참여",
          href: `${URL_SURVEY}/survey/sso/sso_answer_login?location=/survey/list/surveyAnswerList`,
          type: SYSTEM_TYPE,
        },
        {
          title: "온라인 참가 신청",
          subTitle: "안내된 행사 등에 참가 신청",
          href: `${URL_APPLY}/apply/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "입찰 정보",
          subTitle: "우리원 입찰 정보 안내",
          href: `/portal/user/common/commonBoard_list?menuOid=1SqydbhO005`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "우리원 조직 안내",
          subTitle: "조직 정보 안내",
          href: `/portal/user/centerGuide/organization/organization_info`,
          type: HOMEPAGE_TYPE,
        },
      ],
      staffList: [
        {
          title: "GW노트+",
          subTitle: "수업에 필요한 노트 제작",
          href: `${URL_NOTE}/note/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "온라인 참가 신청",
          subTitle: "참가자 모집",
          href: `${URL_APPLY}/apply/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "보결전담강사 예약",
          subTitle: "보결 강사 예약",
          href: `${URL_PROTUTOR}`,
          type: SYSTEM_TYPE,
        },
        {
          title: "온라인 심사",
          subTitle: "서류 제출 받아 심사",
          href: `${URL_CONTEST}/contest/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "원격교육연수",
          subTitle: "정보화 원격연수 수강",
          href: `${URL_LMS}/lms/sso/sso_loginWithType?type=OM`,
          type: SYSTEM_TYPE,
        },
        {
          title: "교직원 집합연수 일정",
          subTitle: "오프라인 연수 일정",
          href: `/portal/user/common/commonScreen?menuOid=1TRvaqvX01E`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "블렌디드러닝 연수",
          subTitle: "찾아가는 교육정보화 연수 수강",
          href: `${URL_LMS}/lms/sso/sso_loginWithType?type=CM`,
          type: SYSTEM_TYPE,
        },
        {
          title: "설문(참여/제작)",
          subTitle: "설문제작, 참여",
          href: `/portal/user/landing/user_landing?survey=Y`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "수리과학정보 체험센터",
          subTitle: "체험센터 예약",
          href: `${URL_MATH}/math/sso/sso_login/`,
          type: SYSTEM_TYPE,
        },
        {
          title: "전자책 출판",
          subTitle: "문서를 전자책으로 변환",
          href: `${URL_EBOOK}/ebook/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "교육연구",
          subTitle: "연구학교/교육자료 조회",
          href: `/portal/user/common/commonBoard_list?menuOid=1Sqy55lw000`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "T-검정",
          subTitle: "연구학교 등 통계분석(T-검정)",
          href: `/portal/user/eduResearch/school/edu_school`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "업무 자료실",
          subTitle: "나이스, 정보원 자료 조회",
          href: `/portal/user/common/commonBoard_list?menuOid=1Sr9pUiq000`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "우리원 조직 안내",
          subTitle: "정보원 담당자 조회",
          href: `/portal/user/centerGuide/organization/organization_info`,
          type: HOMEPAGE_TYPE,
        },
      ]
    }
  },

  computed: {
    ...mapGetters( {
      loginUserInfo: "common/login/loginUserInfo",
    } ),

    overlayStyle() {

      if ( '학생' === this.isType ) {
        return 'student';
      }
      else if ( '학부모' === this.isType  ) {
        return 'parent';
      }
      else if ( '일반' === this.isType ) {
        return 'general';
      }
      else if ( '교직원' === this.isType ) {
        return 'staff';
      }

      return '';
    },
  },

  mounted() {

    window.addEventListener( 'scroll', this.onScroll );

  },

  methods: {
    onViewMenu() {
      this.isClear = ""

      return this.isClear;
    },

    onHideMenu() {
      this.isClear = "is-clear";

      return this.isClear;
    },

    onScroll() {
      if ( 1024 >= window.innerWidth ) {
        this.isType = "";
      }
    },

    onUserType( type ) {
      this.isType = type;

      return this.isType;
    },

    setMenu() {
      this.isType = "";
    },

    goLink( item ) {
      if ( this.$validate.isEmpty( item ) ) {
        return;
      }

      // 타 시스템일 경우 새창
      if ( SYSTEM_TYPE === item.type ) {
        this.goOtherSystem( item );
      }
      // 포털 시스템일 경우 router
      else if ( HOMEPAGE_TYPE === item.type ) {
        this.goHomePage( item );
      }

    },

    goOtherSystem( item ) {
      window.open( item.href );
    },

    goHomePage( item ) {

      // 교직원 > 설문(참여/제작) 일 경우 새창으로 띄웁니다.
      if ( item.href.includes( '/portal/user/landing/user_landing' ) ) {

        const origin = window.location.origin;
        window.open( `${origin}${item.href}` );
        return;
      }

      this.$router.push( item.href )
    },
  }
}
</script>

<style scoped>

</style>
