import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
import excelUtils from '~/plugins/core/utils/excelUtils';
import rsAlertUtils from '~/plugins/core/utils/rsAlertUtils';
import formatUtils from '~/plugins/core/utils/formatUtils';

/**
 *
 */
const portalExcelUtils = (() => {

  const { isEmpty } = typeValidationUtils;
  const { convertExcelParam, downloadExcel } = excelUtils;
  const { rsAlert } = rsAlertUtils;
  const { addCommas } = formatUtils;


  return {

    /**
     * 관리자 > 교육포털 > 회원 그룹 관리 내에서 사용자 엑셀을 다운로드합니다.
     *
     * @param {Object} param
     * @param {string} excelType
     * @param {number} totalCount
     */
    downloadUserListExcel( param, excelType, totalCount ) {

      if ( isEmpty( excelType ) ) {
        return;
      }

      param.excelType = excelType;

      this.convertParamAndDownloadExcel( param, "/api/manager/v1/portalManagerExcelApi_userList", totalCount );
    },

    /**
     * 관리자 > 교육포털 > 통계 관리 > 회원 접속 현황 > 기간별 엑셀 다운로드
     *
     * @param param
     */
    downloadMemChartDateExcel( param ) {

      const excelParam = convertExcelParam( param );
      downloadExcel( "/api/manager/v1/portalManagerExcelApi_listMemChartDate", excelParam );
    },

    /**
     * 관리자 > 교육포털 > 통계 관리 > 회원 접속 현황 > 기관별 접속 현황 상세정보 엑셀 다운로드
     *
     * @param param
     */
    downloadStatsOrgDetailExcel( param ) {

      const excelParam = convertExcelParam( param );
      downloadExcel( "/api/manager/v1/portalManagerExcelApi_statsOrgDetailList", excelParam );
    },

    /**
     * 관리자 > 교육포털 > 통계 관리 > 시스템 방문 현황 > 기간별 엑셀 다운로드
     *
     * @param param
     */
    downloadStatsSystemVisitExcel( param ) {

      const excelParam = convertExcelParam( param );
      downloadExcel( "/api/manager/v1/portalManagerExcelApi_statsSystemVisitList", excelParam );
    },


    /**
     * 교육연구 > 연구학교 > 연구학교-T 검정 서비스 엑셀 다운로드
     *
     * @param param
     */
    downloadTTestExcel( param ) {

      const excelParam = convertExcelParam( param );
      downloadExcel( "/api/manager/v1/ttestExcelDownload", excelParam );
    },

    /**
     * 파라미터를 엑셀파라미터에 맞게 컨버팅 후 엑셀 다운로드를 진행합니다.
     *
     * @param {Object} param
     * @param {string} url
     * @param {number} totalCount
     */
    async convertParamAndDownloadExcel( param, url, totalCount ) {

      if ( isEmpty( url ) ) {
        return;
      }

      if ( totalCount < 1 ) {
        await rsAlert({ contents: "다운로드할 데이터가 없습니다." })
        return;
      }

      // 엑셀 다운로드 수가 100건 이상일 경우 안내문구 출력
      if ( totalCount >= 100 ) {

        const willDownload = await rsAlert({
          title : `총 ${ addCommas( totalCount )} 건이 조회됩니다.`,
          contents: "다운받으시겠습니까?\n"
            + "조회 건수에 따라 더 많은 시간이 소요됩니다.\n"
            + "다운로드를 완료할 때까지 브라우저를 종료하지 말아주세요.",
          useCancelBtn: true
        });

        if ( !willDownload ) {
          return;
        }
      }

      const excelParam = convertExcelParam( param );
      downloadExcel( url, excelParam );
    },

  }
})();

export default portalExcelUtils;