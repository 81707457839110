/**
 * 브라우저 관련 유틸
 *
 */
const browserUtils = (() => {

  const browserConstant = [

    {
      regx: "whale",
      descr: "Whale",
    },
    {
      regx: "edgios|edge|edg",
      descr: "Edge",
    },
    {
      regx: "Opera|OPR|opera mini",
      descr: "Opera"
    },
    {
      regx: "msie|trident|windows phone",
      descr: "Internet Explorer",
    },
    {
      regx: "samsungbrowser",
      descr: "SamsungBrowser",
    },
    {
      regx: "samsung",
      descr: "SamsungInternet",
    },
    {
      regx: "firefox|fxios",
      descr: "Firefox",
    },
    {
      regx: "android",
      descr: "Android Browser"
    },
    {
      regx: "chrome|crios",
      descr: "Chrome",
    },
    {
      regx: "safari|iphone|ipad|ipod",
      descr: "Safari"
    },

  ]

  /**
   * 유저 접속 정보를 체크하는데 사용하는 정규식 모음
   *
   * @property {String} VERSION_CHECK - 브라우저 이름와 합쳐서 버전 정보를 체크하는 정규식 ex) 크롬 -> (chrome|crios)((?:/|s|:)([0-9|.|_]+))
   * @property {RegExp} MOBILE_CHECK  - 모바일 체크용 정규식
   * @property {Array} BROWSER_NAME   - 브라우저 정보
   * @property {Array} WEBVIEW_NAME   - 웹뷰 체크용
   * @property {Array} OS_NAME        - OS 체크 정규식 모음
   * @property {String} DEFAULT_FLAG  - 기본 정규식 플레그 , 전역에서 대소문자 구분없이 검색
   * */
  const visitCheckRegx = {

    VERSION_CHECK : "((?:\/|\\s|:)([0-9|\.|_]+))?",
    MOBILE_CHECK : /mobi/g,
    BROWSER_NAME  : browserConstant,
    WEBVIEW_NAME  : [
      {
        regx: "(?=(iphone|ipad))(?!(.*version))",
        descr: "webview",
      },
      {
        regx: "(?=(android|iphone|ipad))(?=.*(naver|daum|; wv))",
        descr: "webview",
      }
    ],
    OS_NAME : [
      {
        regx: "windows nt",
        descr: "Windows",
        flag : "W"
      },
      {
        regx: "iphone|ipad",
        descr: "IOS",
        flag : "I"
      },
      {
        regx: "mac os x",
        descr: "macOS",
        flag : "M"
      },
      {
        regx: "android",
        descr: "Android",
        flag : "A"
      },
      {
        regx: "webos|web0s",
        descr: "webos",
        flag : "U"
      },
    ],
    DEFAULT_FLAG  : "gi",

  }

  /**
   * UserAgent 대신 UserAgentData로 방문 기록을 가져옵니다.
   *
   * @return {Object} - OS의 이름과 버전
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData}
   * @see
   *  https이면서 브라우저가 chrome 90 + , edge , opera인 경우 사용할 수 있습니다.(21.01.04 기준)
   *  userAgent freez issue 때문에 위 환경일 경우 여기서 데이터를 가져옵니다.
   * */
  async function getUserAgentData() {

    let parsedVisitInfo;
    await navigator.userAgentData.getHighEntropyValues([
      "architecture",
      "model",
      "platform",
      "platformVersion",
      "uaFullVersion",
    ]).then(info => {
      parsedVisitInfo = parseUserAgentData( info );
    });

    return parsedVisitInfo;
  }

  /**
   * testArray에서 정규식으로 데이터를 찾습니다. version을 같이 반환합니다.
   *
   * @param {Object} userAgentData   - 테스트할 기준 Array
   * @return {Object} - testArray에서 찾은 Object
   * */
  function parseUserAgentData( userAgentData ) {

    const brands = userAgentData.brands;

    if ( !brands ) {
      return
    }

    // 모바일 여부
    let isMobile = userAgentData.mobile;

    let platformVersion = userAgentData.platformVersion;

    if ( platformVersion ) {
      platformVersion = convertVersion( platformVersion.replace( /_/g , ".") );
    }
    else {
      platformVersion  = -1;
    }

    // OS 정보
    const osInfo = {
      name    : userAgentData.platform,
      version : platformVersion,
    }

    visitCheckRegx.OS_NAME.some( osItem => {

      const osNameLower = osItem.descr.toLowerCase();
      if ( osNameLower === osInfo.name.toLowerCase() ) {
        osInfo.name = osItem.descr;

        if ( osItem.flag ) {
          osInfo.flag = osItem.flag;
        }

        // 윈도우일 경우 버전을 따로 체크
        if ( "Windows" === osItem.descr ) {

          if ( 13 <= parseInt ( platformVersion.split('.')[0] ) ) {
            platformVersion = 11;
          }

        }

        return true;
      }

      return false;

    } );

    // 해상도
    const resolutionInfo = getResolution();

    // 브라우저 정보
    const browserInfo = {
      name     : "",
      fullVersion  : userAgentData.uaFullVersion,
      isMobile : isMobile
    }

    brands.forEach( brandInfo => {

      const brand = brandInfo.brand.toLowerCase();

      visitCheckRegx.BROWSER_NAME.some(item => {
        if ( !brand.includes(item.descr.toLowerCase() ) ) {
          return false;
        }

        browserInfo.name = item.descr;
        browserInfo.version = brandInfo.version;
        return true;
      })

    });

    return {
      browserInfo,
      osInfo,
      resolutionInfo
    }
  }

  /**
   * 브라우저 정보를 가져옵니다.
   *
   * @param {String} userAgent  - userAgent
   * @return {Object} - 브라우저의 이름과 버전
   * */
  function getBrowserInfo( userAgent ) {

    const browserObj = findInformationWithVersion( visitCheckRegx.BROWSER_NAME , userAgent );

    // 모바일 여부와 WebView 여부를 가져온다.
    browserObj.isMobile = !!visitCheckRegx.MOBILE_CHECK.exec( userAgent.toLowerCase() );
    browserObj.isWebView = !!findInformationWithVersion( visitCheckRegx.WEBVIEW_NAME , userAgent );

    return browserObj;
  }

  /**
   * testArray에서 정규식으로 데이터를 찾습니다. version을 같이 반환합니다.
   *
   * @param {Array} testArray   - 테스트할 기준 Array
   * @param {String} userAgent  - userAgent
   * @return {Object} - testArray에서 찾은 Object
   * @version 1.0 ted : 최초 생성
   * @version 1.1 2022.04.26 ted : 최초 생성 IOS일 경우 version을 다시 구하는 로직에 Safari 추가
   * */
  function findInformationWithVersion( testArray, userAgent ){

    let tempObj = "";

    testArray.some( item => {

      const result = execRegExp(  "(" + item.regx + ")" + visitCheckRegx.VERSION_CHECK , visitCheckRegx.DEFAULT_FLAG , userAgent );

      if( !result ){
        return false;
      }

      // IOS일 경우 version을 다시 구한다.
      if( "safari|iphone|ipad|ipod".includes( item.regx ) || "iphone|ipad" === item.regx || "(?=(iphone|ipad))(?!(.*version))" === item.regx ){

        // 일반 IOS
        const normalIosVersion = execRegExp(  "(iphone os|cpu os)" + visitCheckRegx.VERSION_CHECK , visitCheckRegx.DEFAULT_FLAG , userAgent );

        // 옛 IOS 기기
        if( normalIosVersion ){
          result[3] = normalIosVersion[3];
        }
        else {
          const oldIosVersion = execRegExp(  "(Mac OS X)" + visitCheckRegx.VERSION_CHECK , visitCheckRegx.DEFAULT_FLAG , userAgent );
          result[3] = oldIosVersion ? oldIosVersion[3] : "";
        }

      }

      if ( result[3] ) {
        result[3] = result[3].replace( /_/g , "." );
      }

      tempObj = {};
      tempObj.version = convertVersion( result[3] ) || "-1";
      tempObj.fullVersion = result[3] || "-1";
      tempObj.name = item.descr;
      if( item.flag ){
        tempObj.flag = item.flag;
      }
      return true;

    });

    return tempObj;
  }

  /**
   * OS Version을 가져옵니다.
   *
   * @param {String} userAgent  - userAgent
   * @return {Object} - OS의 이름과 버전
   * */
  function getOsVersion( userAgent ) {
    return findInformationWithVersion( visitCheckRegx.OS_NAME , userAgent );
  }


  /**
   * version을 메이저 버젼만 가져옵니다.
   *
   * @param {String} version   - 정규식
   * */
  function convertVersion( version = "-1" ) {

    const result = version.split( "." )[0];

    if ( result ) {
      return result;
    }
    else {
      return version;
    }

  }

  /**
   * 해상도를 가져옵니다.
   *
   * @return {Object} - 해상도
   * */
  function getResolution() {
    const width  = screen.availWidth || document.body.clientWidth;
    const height = screen.availHeight || document.body.clientHeight;

    return width + "*" + height;
  }

  /**
   * 정규식을 실행합니다.
   *
   * @param {String} pattern   - 정규식
   * @param {String} flag      - 정규식 검사 플레그
   * @param {String} userAgent - userAgent
   * @return {Array|undefined} - 정규식 결과
   * */
  function execRegExp( pattern , flag , userAgent ) {

    return new RegExp(  pattern , flag ).exec( userAgent );
  }

  return {

    /**
     * 브라우저가 ie 브라우저인지 확인합니다.
     *
     * @return {boolean}
     */
    isIE() {
      const agent = navigator.userAgent.toLowerCase();
      return agent.includes("msie") || agent.includes("trident");
    },

    /**
     * 사파리환경인지 확인합니다.
     *
     * @return {boolean}
     */
    isSafari() {
      const agent = navigator.userAgent.toLowerCase();
      return agent.includes('safari') && !agent.includes('chrome') && !agent.includes('android');
    },

    /**
     * webkit(애플사 제품인지) 확인합니다.
     *
     * @return {boolean}
     */
    isWebKit() {

      return /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test( window.navigator.userAgent );
    },

    /**
     * ios 확인
     * import 해서 사용할 경우 독립적으로 사용할 수 없기 때문에 browserUtils.isIos()로 호출
     *
     * @return {boolean|boolean}
     */
    isIos() {

      return this.isWebKit() || this.isSafari();
    },

    /**
     * 브라우저를 반환합니다.
     *
     * @return {string}
     */
    getBrowser() {

      const agent = navigator.userAgent.toLowerCase();

      if ( agent.includes("msie") ) {
        return "ie" + agent.match( /msie (\d+)/ )[ 1 ];
      }
      else if ( agent.includes("trident") ) {
        return "ie11";
      }
      else if ( agent.includes("edg") ) {
        return "edge";
      }
      else if ( agent.includes("firefox") ) {
        return "firefox";
      }
      else if ( agent.includes("opr") ) {
        return "opera";
      }
      else if ( agent.includes("chrome") ) {
        return "chrome";
      }
      else if ( agent.includes("safari") ) {
        return "safari";
      }

      return "";
    },

    /**
     * 운영체제를 반환합니다.
     *
     * @return {string}
     */
    getOs() {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;

      if ( /Android/i.test( userAgent ) ) {
        return "android";
      }
      else if ( /iPhone|iPad|iPod/i.test( userAgent ) ) {
        return "ios";
      }
      else if ( /Mac/i.test( platform ) || /Linux/i.test( platform ) ) {
        return "mac";
      }
      else if ( /Win/i.test( platform ) ) {
        return "windows";
      }
      else {
        return "unknown";
      }
    },

    /**
     * 사용자의 브라우저, OS, 해상도 정보를 가져옵니다.
     * - visitUtils 에서 사용
     *
     * @return {Promise<{osInfo: string, resolutionInfo: string, browserInfo: string}|*>}
     */
    async getClientInfo() {

      if ( navigator.userAgentData ) {
        return await getUserAgentData();
      }

      const userAgent = navigator.userAgent;

      const browserInfo = getBrowserInfo( userAgent );
      const osInfo = getOsVersion( userAgent );
      const resolutionInfo = getResolution();

      return {
        browserInfo,
        osInfo,
        resolutionInfo
      }
    },

  }
})();

export default browserUtils;