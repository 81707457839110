/**
 * Message 유틸
 */
const messageUtils = {

  // 상태 : 입력한 바이트의 길이가 허용범위 내인 경우 true
  isValidByte( text, maxByte ) {

    return this.getByte( text )<= maxByte;
  },

  // 글자가 몇 바이트인지 구하는 함수 (1)
  getByte( str ) {
    return str
    .split( '' )
    .map( s => s.charCodeAt( 0 ) ).reduce( ( prev, unicodeDecimalValue ) => prev
      + this.getByteLength( unicodeDecimalValue ), 0 );
  },

  // 글자가 몇 바이트인지 구하는 함수 (2)
  getByteLength( decimal ) {
    const LINE_FEED = 10; // '\n'
    return (decimal >> 7) || (LINE_FEED === decimal) ? 2 : 1
  },

  // 입력된 글자가 최대 바이트 길이를 넘어가게 되면 최대바이트까지 자르고 나머지만 반환
  getLimitByteText( inputText, maxByte ) {

    const characters = inputText.split( '' );
    let validText = '';
    let totalByte = 0;

    for ( let i = 0; i < characters.length; i += 1 ) {
      const character = characters[i];
      const decimal = character.charCodeAt( 0 );
      const byte = this.getByteLength( decimal ); // 글자 한 개가 몇 바이트 길이인지 구해주기

      // 현재까지의 바이트 길이와 더해 최대 바이트 길이를 넘지 않으면
      if ( totalByte + byte <= maxByte ) {
        totalByte += byte;      // 바이트 길이 값을 더해 현재까지의 총 바이트 길이 값을 구함
        validText += character; // 글자를 더해 현재까지의 총 문자열 값을 구함
      }
      // 최대 바이트 길이를 넘으면 for loop 종료
      else {
        break;
      }
    }

    return validText;
  },

  /**
   * 기존 수신자 대상 중 이름과 연락처가 없는 항목을 제외하고 반환합니다.
   *
   * @param receiverList
   * @return {*}
   */
  clearEmptyReceiverList( receiverList ) {

    if ( $nuxt.$validate.isEmpty( receiverList ) ) {
      return;
    }

    return receiverList.filter( item => $nuxt.$validate.isNotEmpty( item.name ) && $nuxt.$validate.isNotEmpty( item.phone ) );
  },

  /**
   * 연락처 엑셀업로드로 가져온 수신자 리스트를 반환합니다.
   *
   * TODO 신다은 : 해당 함수는 common이 성격에 더 맞습니다.
   * 
   * @param excelList
   * @return {*}
   */
  getReceiverListByExcel( excelList ) {

    if ( $nuxt.$validate.isEmpty( excelList ) ) {
      return;
    }

    return excelList.map( item => {

      const name = item["이름"] ?? "";
      let phone = item["핸드폰번호"] ?? "";

      if ( $nuxt.$validate.isNotEmpty( phone ) && !phone.includes( "-" ) ) {
        phone = $nuxt.$util.formatUtils.formatPhone( phone );
      }

      return { name, phone };
    })
  },

}

export default messageUtils;