/* eslint-disable no-console */
const URL_SEARCH = "/api/user/v1/portalUserSearchApi_search";

const searchSupport = {

  SITE: [
    { 'portal': '강원특별자치도교육청교육과학정보원' },
    { 'ebook': '전자책출판시스템' },
    { 'math': '수리과학정보 체험센터' },
    { 'lms': '원격교육연수 & 블렌디드러닝연수' },
    // survey 게시판은 상세보기가 팝업이라서 일단 주석처리
    // {'survey':'설문조사시스템'},
    // gwnote는 1초마다 수정이 일어나기 때문에 계속 호출..
    // {'gwnote':'교수학습시스템'},
  ],

  SITE_NAME: {

    portal: "portal",
    ebook: "ebook",
    math: "math",
    lms: "lms",
    // survey : "survey",
    // gwnote : "gwnote",
  },

  // 검색결과에 출력할 개수
  SEARCH_RESULT_SHOW_SIZE: 3,

  LOCALSTORAGE_KEY_SEARCH_WORDS: "SEARCH_WORDS",
  LOCALSTORAGE_KEY_SEARCH_WORDS_IN_MAIN: "SEARCH_WORDS_IN_MAIN",

  dateYYYYMMDD: ( date, delim ) => {
    if ( date === '' ) return '';
    if ( delim === undefined ) delim = '';

    const newDate = new Date( date );
    const year = newDate.getFullYear();
    const month = (newDate.getMonth() + 1) < 10 ? "0" + (newDate.getMonth() + 1) : newDate.getMonth() + 1;
    const day = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();

    const result = year + delim + month + delim + day;

    return result;
  },

  saveSearchWordsInMainPage: async ( word ) => {

    await searchSupport.saveSearch( word, searchSupport.LOCALSTORAGE_KEY_SEARCH_WORDS_IN_MAIN );

  },

  /**
   * Localstorage 검색어 추가
   * @param Object searchModel
   */
  saveSearchWords: async ( searchModel ) => {

    await searchSupport.saveSearch( searchModel.search, searchSupport.LOCALSTORAGE_KEY_SEARCH_WORDS );

  },

  saveSearch: async ( word, KEY ) => {
    // 스토리지에서 검색어 꺼냄
    let words = localStorage.getItem( KEY );

    // 검색어가 없으면 -> 최소 생성함
    if ( words === null ) {
      words = [];
      words = JSON.stringify( words );
    }

    // 현재 입력된 검색어 중복여부 확인
    const isInclude = words.indexOf( '"' + word.replace( /"/gi, '\\"' ) + '"' );

    // 중복이면 기존 검색어 제거 후 새로운 검색어추가(날짜갱신을 위해서)
    if ( isInclude > -1 ) {
      await searchSupport.removeSearchWord( word, KEY );
    }

    const newWords = searchSupport.getSearchWords( KEY );

    newWords.push( { word, value: word, date: new Date() } );
    searchSupport.saveLocalStorage( JSON.stringify( newWords ), KEY );
  },

  /**
   * 로컬스토리지에 데이터 저장
   */
  saveLocalStorage: async ( item, KEY ) => {
    await localStorage.setItem( KEY, item );
  },

  /**
   * 검색어 리스트 조회
   * @returns
   */
  getSearchWords: ( KEY ) => {

    const words = localStorage.getItem( KEY );
    if ( words === null ) {
      return []
    }
    else {
      return JSON.parse( words );
    }
  },

  /**
   * 검색어 삭제
   */
  removeSearchWord: async ( word, KEY ) => {

    const words = await searchSupport.getSearchWords( KEY );
    words.forEach( async ( d, i ) => {
      if ( d.word === word ) {
        await words.splice( i, 1 );
      }
    } );
    await searchSupport.saveLocalStorage( JSON.stringify( words ), KEY );
  },

  /**
   * 검색어 전체 제거
   */
  removeAllSearchWord: async ( KEY ) => {
    await localStorage.removeItem( KEY );
  },

  /**
   * 검색호출
   * @param Object axios
   * @param SearchModel searchModel
   */
  search: async ( searchModel ) => {

    const result = new Map();
    const listCountMap = new Map();

    // 파라메터 만들기
    const searchParam = searchSupport.makeParam( searchModel );

    console.log('1111111111111111111111111', searchParam )

    let obj = {
      searchParam,
    }

    if ( searchModel.site.isAllSite ) {

      for ( let i = 0; i < searchSupport.SITE.length; i++ ) {

        obj.site = Object.keys( searchSupport.SITE[i] )[0];

        await $nuxt.$axios.post( URL_SEARCH, obj ).then( res => {

          if ( $nuxt.$validate.isEmpty( res.data ) ) {
            return;
          }

          console.log('222222222222222222', res.data )

          result.set( Object.keys( searchSupport.SITE[i] )[0], res.data.hits.hits );
          listCountMap.set( Object.keys( searchSupport.SITE[i] )[0], res.data.hits.total.value );
        } );
      }
    }
    else {

      obj.site = searchModel.site.siteName;


      await $nuxt.$axios.post( URL_SEARCH, obj ).then( res => {

        if ( $nuxt.$validate.isEmpty( res.data ) ) {
          return;
        }

        result.set( searchModel.site.siteName, res.data.hits.hits );
        listCountMap.set( searchModel.site.siteName, res.data.hits.total.value );
      } );
    }

    return { result, listCountMap };
  },

  // 재검색 일때 조건 만들기
  makeStringConditionForReSearch: ( originString, newString ) => {

    const originWords = searchSupport.makeStringCondition( originString, "OR", true );
    const newWords = searchSupport.makeStringCondition( newString, "OR", true );

    return "(" + originWords + ") AND (" + newWords + ")";
  },

  /**
   * 검색조건만들기
   * @param {String} matchString
   * @param {String} OR_AND
   * @param {Boolean} inAllInclude - 전체포함여부
   * @returns
   */
  makeStringCondition: ( matchString, OR_AND, inAllInclude ) => {

    const orAnd = " " + OR_AND + " ";

    const searchAll = inAllInclude ? "*" : ""

    // 검색 단어 , 로 구분되어서 넘어옴
    const list = matchString === '' ? '' : matchString.trim().split( "," );
    let i = 0;
    let result = "";

    for ( ; i < list.length; i++ ) {
      result += searchAll + list[i] + searchAll + (i === list.length - 1 ? "" : orAnd);
    }
    return result;
  },

  /**
   * 검색엔진에 들어갈 조건 생성
   * @param {String} query
   * @param {String} mustOfNotMust
   * @param {String} fields
   * @returns
   */
  makeSearchEngingCondition: ( query, mustOfNotMust, fields, analyzer, operator ) => {

    return `"${ mustOfNotMust }":[{"query_string":{"fields":[${ fields }],"query":${ query },"analyzer":${ analyzer },"default_operator":${ operator }}}]`;
  },

  /**
   * 검색필드만들기
   * @param {Object} searchModel
   * @returns
   */
  makeParamField: ( searchModel ) => {

    let fields = '';
    // 조회 필드 설정
    // 전체일경우는 기본을 사용
    if ( !searchModel.field.isAll ) {

      // 사용자 일경우는 이름/아이디/이메일을 조회
      if ( searchModel.field.field_name === 'writer' ) {
        fields = '"user_name" , "user_id" , "user_email"'
      }
      else {
        fields = '"' + searchModel.field.field_name + '"';
      }
    }
    else {

      const searchWords = searchModel.search;
      const splitWords = searchWords.split( ' ' );

      let wordCount = splitWords.length;

      if( wordCount >= 3 ) {
        fields = '"post_title.std^3", "contents^2", "file_list^3", "user_name" , "user_id" , "user_email" ';
      }
      else {
        fields = '"post_title.nori^3", "contents^2", "file_list^3", "user_name" , "user_id" , "user_email" ';
      }
    }
    return fields;
  },
  makeParamOrder: ( searchModel ) => {

    let sort = '';

    // 최신순에 따른 정렬
    if ( searchModel.order.isRecently ) {
      sort = '"sort":[{"input_date":"desc"}]';
    }

    return sort;
  },
  makeParamPeriod: ( searchModel ) => {

    let period = '';
    // 전체기간이 아닐경우 일정 세팅
    if ( !searchModel.period.isAll ) {
      // 시작,종료일이 없을 경우 전체 기간으로 조회
      if ( searchModel.period.start !== '' && searchModel.period.end !== '' ) {
        period = `"filter":[{"range":{"input_date":{"gte": "${ searchModel.period.start }", "lte": "${ searchModel.period.end }"}}}]`
      }
    }

    return period;
  },

  /**
   * 검색 조건생성
   * @param {Object} searchModel
   * @returns
   */
  makeParam: ( searchModel ) => {

    // 포함해야 하는 단어
    let must = '';

    // 하이라이트 처리
    let hightlight = '';

    // 포함 기간
    let period = searchSupport.makeParamPeriod( searchModel );

    // 정렬 조건
    let sort = searchSupport.makeParamOrder( searchModel );

    // 검색 필드
    const fields = searchSupport.makeParamField( searchModel );

    const reSearch = searchModel.reSearch;

    // 재검색
    if ( reSearch ) {
      const searchCondition = searchSupport.makeStringConditionForReSearch( searchModel.search, searchModel.reSearchWords );

      // console.log( '############# searchCondition', searchCondition );

      must = searchSupport.makeSearchEngingCondition( `"${ searchCondition }"`, "must", fields );
    }

    // 검색
    else if ( searchModel.search !== '' ) {

      const searchWords = searchModel.search;
      const splitWords = searchWords.split( ' ' );

      let resultWordsMust = '';
      let resultWordsNotMust = '';
      let checkCondiction = false;
      let detailSearchByNot = false;
      let detailTerms = []; // 상세검색어
      let generateTerms = []; // 일반검색어

      let wordCount = splitWords.length;

      // 상세검색어와 일반검색어를 분리한다
      splitWords.forEach( word => {

        // 상세검색어인 경우
        if ( word.startsWith( "|" ) || word.startsWith( "&" ) || word.startsWith( "\"" ) || word.startsWith( "!" ) ) {
          detailTerms.push( word );
        }
        // 일반검색어인 경우
        else {
          generateTerms.push( word );
        }
      });

      // 일반검색어가 있는 경우
      if ( generateTerms.length > 0 ) {

        generateTerms.forEach( word => {
          // 3단어 이상이면서 상세검색어가 있는 경우 일반검색어는 OR로 묶어준다
          if ( wordCount >= 3 && detailTerms.length > 0 ) {
            resultWordsMust += (resultWordsMust === '' ? '' : ' OR ') + word;
          }
          else if ( wordCount >= 3 && detailTerms.length == 0 ) {
            resultWordsMust += (resultWordsMust === '' ? '' : ' ') + word;
          }
          else {
            resultWordsMust += (resultWordsMust === '' ? '' : '^2 ') + word;
          }
        })
      }

      if( wordCount >= 3 && detailTerms.length > 0 ) {
        resultWordsMust = '(' + resultWordsMust + ')'
      }

      // 상세검색어가 있는 경우
      if ( detailTerms.length > 0 ) {

        detailTerms.forEach( word => {

          if ( word.startsWith( "|" ) ) {
            if ( wordCount === 1 ) {

              $nuxt.$util.rsAlertUtils.rsAlert( { contents: "상세검색을 바로 할 수 없습니다." } );
              return '';
            }

            word = word.replace( /\|/gi, '' );
            resultWordsMust += (resultWordsMust === '' || resultWordsMust.endsWith( '(' ) ? '' : ' OR (') + word + ')';
            checkCondiction = true;
          }

          if ( word.startsWith( "&" ) ) {
            if ( wordCount === 1 ) {

              $nuxt.$util.rsAlertUtils.rsAlert( { contents: "상세검색을 바로 할 수 없습니다." } );
              return '';
            }

            word = word.replace( /&/gi, '' );
            if ( word !== '' ) {
              resultWordsMust += (resultWordsMust === '' ? '' : ' AND (') + word + ')';
            }
            else {
              resultWordsMust += (resultWordsMust === '' ? '' : ' AND ');
            }
            checkCondiction = true;
          }

          if ( word.startsWith( "\"" ) ) {
            if ( wordCount === 1 ) {

              $nuxt.$util.rsAlertUtils.rsAlert( { contents: "상세검색을 바로 할 수 없습니다." } );
              return '';
            }

            word = word.replace( /"/gi, '' );
            resultWordsMust += (resultWordsMust === '' ? '' : ' (\\"') + word + '\\")';
            checkCondiction = true;
          }

          if ( word.startsWith( "!" ) ) {

            if ( wordCount === 1 ) {

              $nuxt.$util.rsAlertUtils.rsAlert( { contents: "상세검색을 바로 할 수 없습니다." } );
              return '';
            }

            word = word.replace( /!/gi, '' );
            resultWordsMust += (resultWordsMust === '' ? '' : ' (NOT ') + word + ')';
            checkCondiction = true;
            detailSearchByNot = true;
          }
        } )
      }

      if ( resultWordsMust !== '' ) {
        if( checkCondiction ) {
          if( wordCount >= 3 ) {
            must = searchSupport.makeSearchEngingCondition( `"${ resultWordsMust }"`, "must", fields, `"standard"`, `"AND"` );
            hightlight = "post_title.std";
          }
          else {
            if( detailSearchByNot ) {
              must = searchSupport.makeSearchEngingCondition( `"${ resultWordsMust }"`, "must", fields, `"standard"`, `"AND"` );
              hightlight = "post_title.std";
            }
            else {
              must = searchSupport.makeSearchEngingCondition( `"${ resultWordsMust }"`, "must", fields, `"nori"`, `"AND"` );
              hightlight = "post_title.nori";
            }
          }
        }
        else {
          if( wordCount >= 3 ) {
            must = searchSupport.makeSearchEngingCondition( `"${ resultWordsMust }"`, "must", fields, `"standard"`, `"OR"` );
            hightlight = "post_title.std";
          }
          else {
            must = searchSupport.makeSearchEngingCondition( `"${ resultWordsMust }"`, "must", fields, `"nori"`, `"OR"` );
            hightlight = "post_title.nori";
          }
        }
      }
    }

    if( must === '') {
      return;
    }

    const sortStr = $nuxt.$validate.isEmpty( sort ) ? '' : sort + ",";

    let params = {};

    if ( searchModel.site.isAllSite ) {

      params = '{ ' +
        ' "size" : 10000,' +
        '     "sort": [ ' +
        '         {' +
        '            "_score": { "order": "desc" }' +
        '          },' +
        '         {' +
        '            "input_date": { "order": "desc" }' +
        '          }' +
        '     ],' +
        '     "query": {' +
        '         "bool":{' +
        '           ' + must + '' +
        '           ' + ((period !== '' && must !== '') ? "," : '') + period + '' +
        '         }' +
        '    }' + "," +
        `    "highlight": {
                      "fields": [
                        { "`+ hightlight + `" : {} },
                        { "contents": { "number_of_fragments" : 0 } },
                        { "user_name": {} },
                        { "file_list": {} }
                      ]
                   }
              }`;
    }
    else {

      params = '{ ' +
        ' "from" : ' + searchModel.startIndex + ',' +
        ' "size" : ' + searchModel.pageSize + ',' +
        '     "sort": [ ' +
        '         {' +
        '            "_score": { "order": "desc" }' +
        '          },' +
        '         {' +
        '            "input_date": { "order": "desc" }' +
        '          }' +
        '     ],' +
        '     "query": {' +
        '         "bool":{' +
        '           ' + must + '' +
        '           ' + ((period !== '' && must !== '') ? "," : '') + period + '' +
        '         }' +
        '    }' + "," +
        `    "highlight": {
                      "fields": [
                        { "`+ hightlight + `": {} },
                        { "contents": { "number_of_fragments" : 0 } },
                        { "user_name": {} },
                        { "file_list": {} }
                      ]
                   }
              }`;
    }

    return params;
  },
}

export default searchSupport;
