import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
import formatUtils from '~/plugins/core/utils/formatUtils';
import { PORTAL_CONSTANT } from '~/constant/site/portal/portalConstant';
import portalOrgCommon from "~/plugins/portal/common/portalOrgCommon";

/**
 * 포털 통계 유틸
 *
 */
const portalStatsUtils = (() => {

  const { isEmpty } = typeValidationUtils;
  const { convertMapToList } = formatUtils;

  async function getOrgAreaInfo( areaCode ){
    let areaCodeList = await portalOrgCommon.getOrgAreaCodeList();

    let selectedAreaInfo = areaCodeList.filter( area => {
      return area.codeKey === areaCode
    });

    return selectedAreaInfo[0].codeValue;
  }


  return {

    /**
     * map을 list령태로 변경합니다.
     *
     * @param mapData
     * @return {{typeValue: *, type: *}[]|*[]}
     */
    mapToListByType ( mapData ) {

      return convertMapToList( mapData, "type", "typeValue" );
    },

    /**
     * orgName에 따라 map을 list 형태로 변경합니다.
     *
     * @param mapData
     * @return {{}[]|*[]}
     */
    mapToListByOrg( mapData ) {

      return convertMapToList( mapData, "orgName", "orgValue" );
    },

    /**
     * 리스트에서 총 statsCount를 구합니다.
     *
     * @param statsList
     * @return {*|number}
     */
    getStatsTotalCountByList( statsList ) {

      if ( isEmpty( statsList ) ) {
        return 0;
      }

      return statsList.reduce( ( sum, item ) => sum + item.orgValue.statsCount, 0 );
    },

    /**
     * 리스트에서 총 statsCountPercent 더해 전체 퍼센트를 구합니다.
     *
     * @param statsList
     * @return {number|*|number}
     */
    getStatsTotalPercentByList( statsList ) {

      if ( isEmpty( statsList ) ) {
        return 0;
      }

      const totalPercent = statsList.reduce( ( sum, item ) => sum + item.orgValue.statsCountPercent, 0 );
      return totalPercent > 100 ? 100 : Math.round( totalPercent );
    },

    async sumListFromStats( arrayList ) {

      if ( isEmpty( arrayList ) ) {
        return;
      }

      let sumTotal = {
        totalSum: 0,
        studentSum: 0,
        parentSum: 0,
        staffSum: 0,
        staffGangwonSum: 0,
        normalSum: 0,
      }

      await arrayList.forEach(item => {

        let typeValue = item.typeValue;

        for ( let mapName in typeValue ) {

          if ( PORTAL_CONSTANT.USER_TYPE.ADMIN.KEY !== mapName) {
            sumTotal.totalSum += typeValue[mapName];
          }

          switch (mapName) {
            case PORTAL_CONSTANT.USER_TYPE.STUDENT.KEY :
              sumTotal.studentSum += typeValue[mapName];
              break;
            case PORTAL_CONSTANT.USER_TYPE.PARENT.KEY :
              sumTotal.parentSum += typeValue[mapName];
              break;
            case PORTAL_CONSTANT.USER_TYPE.STAFF.KEY :
              sumTotal.staffSum += typeValue[mapName];
              break;
            case PORTAL_CONSTANT.USER_TYPE.STAFF_GANGWON.KEY :
              sumTotal.staffGangwonSum += typeValue[mapName];
              break;
            case PORTAL_CONSTANT.USER_TYPE.NORMAL.KEY :
              sumTotal.normalSum += typeValue[mapName];
              break;
          }
        }
      })

      return sumTotal;
    },

    /**
     * @param queryParamSearchType 검색 기간 타입 ( YEAR, MONTH, DAY )
     * @param queryParamPeriod 검색 기간 타입이 YEAR 또는 MONTH인 경우, 년-월-일 형태로 검색 기간이 담긴다. DAY인 경우, 검색 기간의 시간만 담긴다.
     * @param queryParamDay 검색 기간 타입이 DAY인 경우, 검색 일자가 년-월-일 형태로 담긴다
     *
     * @return {fullPeriod: *[], statsHour: string} statsHour와 fullPeriod 배열이 담긴 오브젝트를 리턴한다 (statsHour: 검색 일자의 시간, fullPeriod[]: 검색 기간이 년, 월, 일 순서의 배열)
     * */
    getFullPeriod( queryParamSearchType, queryParamPeriod, queryParamDay ) {

      let fullPeriodObj = {
        statsHour: '',
        fullPeriod : [],
      };

      // 시간인 경우
      if ( PORTAL_CONSTANT.STATS_SEARCH_TYPE.DAY.KEY === queryParamSearchType ) {
        fullPeriodObj.statsHour = queryParamPeriod.substr( 0, 2 );
      }

      fullPeriodObj.fullPeriod = queryParamPeriod.split( '-' )

      return fullPeriodObj;

    },

    /**
     * 이전 화면에서 라우터로 넘어온 검색 기간을 object로 만들어 반환합니다
     *
     * @param fullPeriodObj statsHour와 fullPeriod 배열이 담긴 오브젝트
     * @param period 검색 기간 ( statsYear, statsMonth, statsDay, statsHour를 포함하는 객체 )
     * @returns Object
     */
    setPeriod( fullPeriodObj,  period ) {

      let periodCopy = Object.assign( {}, period);

      if ( fullPeriodObj.statsHour ) {
        periodCopy.statsHour = fullPeriodObj.statsHour;
      }

      periodCopy.statsYear = fullPeriodObj.fullPeriod[0];
      periodCopy.statsMonth = fullPeriodObj.fullPeriod[1];
      periodCopy.statsDay = fullPeriodObj.fullPeriod[2];

      return periodCopy;
    },

    /**
     * 상세 화면으로 넘어갈 시 라우터로 넘길 period 포맷을 만듭니다
     * @param period
     * @param searchType
     * @returns string
     * YYYY / YYYY-MM / YYYY-MM-DD
     */
    getPeriod( period, searchType ) {
      const stMonth = period.statsMonth;
      const stDay = period.statsDay;

      // 월이 없는 경우 -> 월간 검색
      if ( stMonth === undefined ) {
        return period.statsYear
      }
      // 일이 없는 경우 -> 일간 검색
      if ( stDay === undefined ){
        return period.statsYear.concat( "-", stMonth );
      }
      // 시간 검색
      if ( PORTAL_CONSTANT.STATS_SEARCH_TYPE.DAY.KEY === searchType ) {
        return period.statsHour;
      }

      return period.statsYear.concat( "-", stMonth, "-", stDay );

    },

    /**
     * 기간 정보를 상단 화면에 다시 포맷팅하여 출력
     */
    getPeriodLabel( period, searchType ) {

      // 시간인 경우
      if ( PORTAL_CONSTANT.STATS_SEARCH_TYPE.DAY.KEY === searchType ) {
        return period.statsYear + '년 ' + period.statsMonth + '월 ' + period.statsDay
          + '일 ' + period.statsHour + '시';
      }

      if ( isEmpty( period.statsMonth ) ) {
        return period.statsYear + '년';
      }
      if ( isEmpty( period.statsDay ) ) {
        return period.statsYear + '년 ' + period.statsMonth + '월';
      }

      return period.statsYear + '년 ' + period.statsMonth + '월 ' + period.statsDay + '일';
    },

    /**
     * 선택된 지역의 이름을 가져옵니다 (기관별 통계 화면의 지역 셀렉트박스에서 사용)
     * @param selectedArea
     * @param selectedGangwonArea
     * @returns {string|*}
     */
    async getAreaName( selectedArea, selectedGangwonArea ) {

      // 전체인 경우
      if ( isEmpty( selectedArea ) ) {
        return "전체"
      }

      if ( selectedArea === PORTAL_CONSTANT.AREA_CODE.GANGWON &&  isEmpty( selectedGangwonArea ) ) {
        return "강원특별자치도 전체";
      }

      if ( selectedArea === PORTAL_CONSTANT.AREA_CODE.GANGWON ) {
        return "강원특별자치도 " + selectedGangwonArea;
      }

      // 지역이 강원특별자치도 외 지역인 경우
      if ( selectedArea !== PORTAL_CONSTANT.AREA_CODE.GANGWON ) {
        return await getOrgAreaInfo( selectedArea );
      }
    },

    getStatsNameByType( statsType ) {

      if ( PORTAL_CONSTANT.STATS_TYPE_FLAG.LOGIN.KEY === statsType ) {
        return PORTAL_CONSTANT.STATS_TYPE_FLAG.LOGIN.NAME;
      }
      else if ( PORTAL_CONSTANT.STATS_TYPE_FLAG.JOIN.KEY === statsType ) {
        return PORTAL_CONSTANT.STATS_TYPE_FLAG.JOIN.NAME;
      }
      else if ( PORTAL_CONSTANT.STATS_TYPE_FLAG.WITHDRAWAL.KEY === statsType ) {
        return PORTAL_CONSTANT.STATS_TYPE_FLAG.WITHDRAWAL.NAME;
      }
      return '';
    },

  }
})();

export default portalStatsUtils;