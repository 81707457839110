import portalOrgCommon from "~/plugins/portal/common/portalOrgCommon";
import portalSearchCommon from "~/plugins/portal/common/portalSearchCommon";
import portalCodeCommon from "~/plugins/portal/common/portalCodeCommon";
import portalExternalRole from "~/plugins/portal/common/portalExternalRole";
import portalEpkiCommon from "~/plugins/portal/common/portalEpkiCommon";
import portalForbiddenCommon from "~/plugins/portal/common/portalForbiddenCommon";
import portalGoogleWorkspace from "~/plugins/portal/common/portalGoogleWorkspace";
import { sessionStrategyProvider } from "~/plugins/core/setting/clientSessionStrategy";

const portalCommon = {

  // Portal Organization 관련
  ...portalOrgCommon,

  // Portal Search 관련
  ...portalSearchCommon,

  // Code 관련
  ...portalCodeCommon,

  // 외부 Role 관련
  ...portalExternalRole,

  // EPKI 관련
  ...portalEpkiCommon,

  // 금칙어 관련
  ...portalForbiddenCommon,

  // 구글 워크스페이스 관련
  ...portalGoogleWorkspace,


}

export default ( context, inject ) => {

	portalCommon.sessionStrategy = sessionStrategyProvider( context, "cookie" );

	inject( "portalCommon", portalCommon );
};
