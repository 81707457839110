import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';

/**
 * QR 코드 유틸
 */
const qrCodeUtils = (() => {

  const { isEmpty } = typeValidationUtils;

  return {

    /**
     * QR 코드를 가져옵니다.
     *
     * @param {string} url
     * @return {Promise<string>}
     */
    async getQrCode( url ) {

      if ( isEmpty( url ) ) {
        return "";
      }

      const { data } = await $nuxt.$axios.post( "/barcode/qrcodeApi_makeQrcodeBase64?barcode=" + url );

      if ( isEmpty( data ) ) {
        return "";
      }

      return "data:image/png;base64," + data;
    },

    /**
     * QR 코드를 다운로드합니다.
     *
     * @param {string}url
     */
    downloadQrCode( url ) {

      if ( isEmpty( url ) ) {
        return;
      }

      $nuxt.$axios( {
        url : "/barcode/qrcodeApi_downQrcode?barcode=" + url,
        method: "POST",
        responseType: "blob",
      } ).then( (response ) => {

        if ( !window.navigator.msSaveOrOpenBlob ) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL( new Blob( [response.data] ) );
          const link = document.createElement( "a" );
          link.href = url;
          link.setAttribute( "download", "qrcode.png" );
          document.body.appendChild( link );
          link.click();
        }
        else {
          // BLOB FOR EXPLORER 11
          const url = window.navigator.msSaveOrOpenBlob( new Blob( [response.data] ),
            "qrcode.png" );
        }
      })

    },

  }

})();

export default qrCodeUtils;