export const state = () =>({
  orgUnitsList : [],
  selectedOrg : {},
})
export const mutations = {

  SET_INIT(state){
    state.orgUnitsList = [];
  },

  SET_ORGANIZATION_LIST( state, orgsList ){
    state.orgUnitsList = orgsList;
  },

  SET_SELECTED_ORG( state, selectedOrg ){
    state.selectedOrg = selectedOrg;
  },

}
export const actions = {

  async getOrgUnitsTree( { commit } ){

    commit( "SET_INIT" );
    try {
      const res = await this.$googleDirectory.getOrgUnitsTree();
      console.log(res)
      if( this.$validate.isEmpty( res ) ){
        return;
      }
      else{
        console.log(res.data.organizationUnits);
        commit( "SET_ORGANIZATION_LIST", res.data.organizationUnits );
      }
    }
    catch ( e ) {
      console.error( e )
    }
  },

  async getSelectedOrg( { commit }, selectedOrg ){

    commit( "SET_SELECTED_ORG", selectedOrg );
  }

}

export const getters = {

  orgUnitsList : (state) => async() => {
    if ( $nuxt.$validate.isEmpty( state.orgUnitsList ) ) {
      await $nuxt.$store.dispatch( "portal/workspace/orgUnitsList" )
      return state.orgUnitsList;
    }
  },

  selectedOrg (state) {
    if ( $nuxt.$validate.isEmpty( state.selectedOrg ) ) {
      $nuxt.$store.dispatch( "portal/workspace/getSelectedOrg" )
    }
    return state.selectedOrg;
  },

}
