const froalaEditorUtil = {

  /**
   * - dom 구조로 되어있는 string을 받아, 파싱한 뒤, 인라인 이벤트 핸들러를 모두 제거합니다. 그 뒤 다시 string으로 변경된 값을 리턴합니다.
   * - 본 함수는, 웹 취약점 => XSS 중 즉시 실행되는 인라인 이벤트 핸들러를 이용한 공격을 방지하기 위한 필터 역할입니다.
   * @param {string} content
   * @return {string}
   * */
  removeAllInlineEventHandler( content ) {

    const domParser = new DOMParser();
    const parsedDom = domParser.parseFromString( content, "text/html" );
    const rootElement = parsedDom.documentElement;

    const removeAllInlineEventHandler = ( element ) => {
      Array.from( element.attributes ).forEach( attribute => {
        if ( attribute.name.startsWith( 'on' ) ) {
          element.removeAttribute( attribute.name );
        }
      } );

      Array.from( element.children )
        .forEach( child => removeAllInlineEventHandler( child ) )
    }

    removeAllInlineEventHandler( rootElement )

    const newElement = rootElement.querySelector( "body" );

    return newElement.innerHTML;
  },

}

export default froalaEditorUtil;
