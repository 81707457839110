const URL_USER_LIST = "/api/google/v1/directory/googleDirectoryApi_getUsersList";
const URL_USER_LIST_WITHOUT_COUNT = "/api/google/v1/directory/googleDirectoryApi_getUsersListWithoutCount";
const URL_USER_PAGE_TOKEN_LIST = "/api/google/v1/directory/googleDirectoryApi_getUsersListPageToken";
const URL_CHANGE_STATUS_BULK = "/api/google/v1/directory/googleDirectoryApi_suspendUserBulk";
const URL_DELETE_USER_BULK = "/api/google/v1/directory/googleDirectoryApi_deleteUserBulk";
const URL_UNDELETE_USER_BULK = "/api/google/v1/directory/googleDirectoryApi_unDeleteUserBulk";

const URL_GET_USER_ROOT = "/api/google/v1/directory/googleDirectoryApi_getUser";
const URL_STATUS_ROOT = "/api/google/v1/directory/googleDirectoryApi_suspendUser";
const URL_CHANGE_PASS_ROOT = "/api/google/v1/directory/googleDirectoryApi_changePassword";
const URL_CHANGE_PASS_BULK = "/api/google/v1/directory/googleDirectoryApi_changePasswordBulk";
const URL_UPDATE_USER_ROOT = "/api/google/v1/directory/googleDirectoryApi_updateUser";
const URL_DELETE_USER_ROOT = "/api/google/v1/directory/googleDirectoryApi_deleteUser";
const URL_UNDELETE_USER_ROOT = "/api/google/v1/directory/googleDirectoryApi_unDeleteUser";

const URL_USER_INSERT = "/api/google/v1/directory/googleDirectoryApi_insertUser";
const URL_INSERT_USER_BULK = "/api/google/v1/directory/googleDirectoryApi_insertUserBulk";
const URL_USER_ID_EXISTENCE_VALIDATION_ROOT = "/api/google/v1/directory/googleDirectoryApi_userExistenceValidation";
const URL_USER_ID_EXISTENCE_VALIDATION_BULK = "/api/google/v1/directory/googleDirectoryApi_userExistenceValidationBulk";

//조직
const URL_GET_ORGUNIT = "/api/google/v1/directory/googleDirectoryApi_getOrgunit";
const URL_ORGUNIT_LIST_ROOT = "/api/google/v1/directory/googleDirectoryApi_getOrgunitsList";
const URL_ORGUNIT_TREE = "/api/google/v1/directory/googleDirectoryApi_getOrgunitsTree";
// const URL_ORGUNIT_TREE_ALL = "/api/google/v1/directory/googleDirectoryApi_getOrgunitsList?orgUnitPath=%2F&type=ALL";
const URL_ORGUNIT_TREE_ALL = "/api/google/v1/directory/googleDirectoryApi_getOrgunitsList?orgUnitId=/newportal&type=ALL";
const URL_INSERT_OR_UPDATE_ORGUNIT = "/api/google/v1/directory/googleDirectoryApi_insertOrUpdateOrgunit";
const URL_UPDATE_ORGUNIT = "/api/google/v1/directory/googleDirectoryApi_updateOrgunit";
const URL_GET_SCHOOL_NAME_LIST = "/api/google/v1/directory/googleDirectoryApi_getSchoolNameList";

//역할
const URL_GET_PRIVILEGES_LIST = "/api/google/v1/directory/googleDirectoryApi_getPrivilegesList";
const URL_GET_ROLE_LIST = "/api/google/v1/directory/googleDirectoryApi_getRolesList";
const URL_GET_ROLE_ASSIGNMENTS_LIST_BY_ROLE_ID = "/api/google/v1/directory/googleDirectoryApi_getRolesAssignmentsListByRoleId";

//배치(다건) 처리
const URL_INSERT_USER_BATCH = "/api/google/v1/directory/googleDirectoryApi_insertUserBatch";
const URL_DELETE_USER_BATCH = "/api/google/v1/directory/googleDirectoryApi_deleteUserBatch";

let userList_defaultParam = {
  maxResult: 20,
  query: '',
  page: 1,
  pageToken: '',
};

let deletedUserList_defaultParam = {
  maxResult: 20,
  query: '',
  page: 1,
  pageToken: '',
  showDeleted: "true",
};

let changeUserStatusBulk_defaultParam = {
  userKeyList: [],
  status: true, //true/false
  taskUserOid: '',
};
let deleteUsersBulk_defaultParam = {
  userInfoList: [],
  taskUserOid: '',
};

let undeleteUsersBulk_defaultParam = {
  userInfoList: [],
  taskUserOid: '',
};

let userIdExistenceCheckBulk_defaultParam = {
  userKeyList: [],
};

let changeUserPasswordBulk_defaultParam = {
  userKeyList: [],
  password: '',
  changePasswordAtNextLogin: false,
};

let userInsert_defaultParam = {
  primaryEmail: '',
  password: '',
  orgUnitPath: '',
  familyName: '',
  givenName: '',
  userType: '',
  secondaryEmail: '',
  taskUserOid: '',
}

let userInsertBulk_defaultParam = {
  userInfoList: [],
  userType: 'STUDENT', //유저 타입(STUDENT / TEACHER)
  taskUserOid: '',
}

let insertOrUpdateOrg_defaultParam = {
  name: '',
  parentOrgUnitPath: '',
  description: '',
  orgUnitPath: '',
  // 'orgUnitId':'',
}

let getOrg_defaultParam = {
  orgUnitPath: '/',
}

/**/

/**[ 개요 ]
 * 구글 워크스페이스의 디렉토리 서비스를 호출하는 api 입니다.
 * 호출할 URL 과 defaul Param 을 상단에 작성하였고, 하단에 export 되는 함수는 axios 로 api 를 호출한 결과를 리턴합니다.
 * 회원 가입시 GPKI 교직원 인증을 통해 가입한 강원도 교직원에게 워크스페이스 아이디를 생성시 사용합니다.
 * 또한 통합 관리자의 워크스페이스 회원관리(조회, 상태변경, 삭제 등)에 사용되며
 * myPage 에서 사용자(강원도 교직원)가 다건의 학생 계정 생성시에 사용됩니다.
 *
 * (참고) 다건(bulk)을 다루는 api 를 사용할 경우 axios 호출시
 * config 의 timeout 이 짧으면 통신에 실패하게 되어 시간을 늘리도록 설정해야 합니다.
 * 아직 작성중으로, 필요한 내용을 추가하고 설명이 필요한 부분은 주석 작성하겠습니다.
 ***************************************************************************************************/
const googleDirectory = {

  //유저 목록
  async getUsersList( param = userList_defaultParam ) {
    return $nuxt.$axios.post( URL_USER_LIST, param, {timeout: 100000} );
  },

  //삭제된 유저 목록
  async getDeletedUserList( param = deletedUserList_defaultParam ) {
    return $nuxt.$axios.post( URL_USER_LIST, param, {timeout: 100000} );
  },

  // 유저 목록 ( 유저 개수는 안가져옵니다. )
  async getUsersListWithoutCount( param = userList_defaultParam ) {
    return $nuxt.$axios.post( URL_USER_LIST_WITHOUT_COUNT, param, {timeout: 100000} );
  },

  //삭제된 유저 목록( 유저 개수는 안가져옵니다. )
  async getDeletedUserListWithoutCount( param = deletedUserList_defaultParam ) {
    return $nuxt.$axios.post( URL_USER_LIST_WITHOUT_COUNT, param, {timeout: 100000} );
  },

  //유저 목록 페이지 토큰 리스트
  async getUsersPageTokenList( param = userList_defaultParam ) {
    return $nuxt.$axios.post( URL_USER_PAGE_TOKEN_LIST, param, {timeout: 100000} );
  },

  //[유저 다건] 상태(일시정지/활성)변경
  async changeUserStatusBulk( param = changeUserStatusBulk_defaultParam ) {

    // 작업 수행자 추가
    param.taskUserOid = this.getTaskUserOid( param?.taskUserOid );
    return $nuxt.$axios.post( URL_CHANGE_STATUS_BULK, param, {timeout: 100000} );
  },

  //[유저 다건] 계정삭제
  async deleteUserBulk( param = deleteUsersBulk_defaultParam ) {

    // 작업 수행자 추가
    param.taskUserOid = this.getTaskUserOid( param?.taskUserOid );
    return $nuxt.$axios.post( URL_DELETE_USER_BULK, param, {timeout: 100000 * 100} );
  },

  //[유저 다건] 계정삭제(배치)
  async deleteUserBatch( param = deleteUsersBulk_defaultParam ) {

    // 작업 수행자 추가
    param.taskUserOid = this.getTaskUserOid( param?.taskUserOid );
    return $nuxt.$axios.post( URL_DELETE_USER_BATCH, param, {timeout: 100000 * 100} );
  },

  //[유저 다건] 삭제된 계정 복구
  async undeleteUserBulk( param = undeleteUsersBulk_defaultParam ) {

    // 작업 수행자 추가
    param.taskUserOid = this.getTaskUserOid( param?.taskUserOid );
    return $nuxt.$axios.post( URL_UNDELETE_USER_BULK, param, {timeout: 100000} );
  },

  //[유저 단건] 상세정보 조회
  async getUser( userKey ) {
    return $nuxt.$axios.get( `${URL_GET_USER_ROOT}/${userKey}` );
  },

  //[유저 단건] 상태 변경
  async changeUserStatus( userKey, status = false, userOid = '' ) {

    // 작업 수행자 추가
    const taskUserOid = this.getTaskUserOid( userOid );
    return $nuxt.$axios.get( `${URL_STATUS_ROOT}/${userKey}?status=${status}&taskUserOid=${taskUserOid}` );
  },

  //[유저 단건] 정보 변경
  async updateUserData( userKey, param, url = URL_UPDATE_USER_ROOT ) {
    return $nuxt.$axios.post( `${url}/${userKey}`, param );
  },

  //[유저 단건] 비밀번호 변경
  async changeUserPassword( userKey, param ) {
    return $nuxt.$axios.post( `${URL_CHANGE_PASS_ROOT}/${userKey}`, param );
  },

  //[유저 다건] 비밀번호 변경
  async changeUserPasswordBulk( param = changeUserPasswordBulk_defaultParam ) {
    return $nuxt.$axios.post( URL_CHANGE_PASS_BULK, param, {timeout: 100000} );
  },

  //[유저 단건] 계정 삭제
  async deleteUser( userKey, param ) {

    // 작업 수행자 추가
    param.taskUserOid = this.getTaskUserOid( param?.taskUserOid );
    return $nuxt.$axios.post( `${URL_DELETE_USER_ROOT}/${userKey}`, param );
  },

  //[유저 단건] 삭제된 계정 복구
  async unDeleteUser( userKey, param ) {

    // 작업 수행자 추가
    param.taskUserOid = this.getTaskUserOid( param?.taskUserOid );
    return $nuxt.$axios.post( `${URL_UNDELETE_USER_ROOT}/${userKey}`, param );
  },

  //[유저 단건] 중복 아이디 조회
  async userIdExistenceCheck( primaryEmail ) {
    return $nuxt.$axios.get( `${URL_USER_ID_EXISTENCE_VALIDATION_ROOT}/${primaryEmail}` );
  },

  //[유저 다건] 중복 아이디 조회
  async userIdExistenceCheckBulk( param = userIdExistenceCheckBulk_defaultParam ) {

    // userKeyList에 빈값이 있을 경우 타지 않도록 처리
    if ( $nuxt.$validate.isEmpty( param.userKeyList ) || param.userKeyList.some( userKey => $nuxt.$validate.isEmpty( userKey ) ) ) {
      $nuxt.$util.rsAlertUtils.rsAlert( {
        title: '구글 아이디 확인',
        contents: `구글 아이디를 모두 입력해주세요.`,
        state: 'error'
    } );
      return false;
    }

    return $nuxt.$axios.post( URL_USER_ID_EXISTENCE_VALIDATION_BULK,
      param,
      {timeout: 100000 * 100} );
  },

  //[유저 단건] 계정 생성 ~> 작성중. 테스트 해볼것.
  async insertUser( param = userInsert_defaultParam ) {

    // 작업 수행자 추가
    param.taskUserOid = this.getTaskUserOid( param?.taskUserOid );
    return $nuxt.$axios.post( URL_USER_INSERT, param );
  },

  //[유저 다건] 계정 생성
  async insertUserBulk( param = userInsertBulk_defaultParam ) {

    // 작업 수행자 추가
    param.taskUserOid = this.getTaskUserOid( param?.taskUserOid );
    return $nuxt.$axios.post( URL_INSERT_USER_BULK, param, {timeout: 100000 * 100} );
  },

  //[유저 다건] 계정 생성(배치)
  async insertUserBatch( param = userInsertBulk_defaultParam ) {

    // 작업 수행자 추가
    param.taskUserOid = this.getTaskUserOid( param?.taskUserOid );
    return $nuxt.$axios.post( URL_INSERT_USER_BATCH, param, {timeout: 100000 * 100} );
  },

  // 조직 단건 조회
  async getOrgUnit( param = getOrg_defaultParam ) {
    return $nuxt.$axios.get( `${URL_GET_ORGUNIT}?orgUnitPath=${encodeURIComponent( param.orgUnitPath )}`,
      {timeout: 100000} );
  },

  //조직 목록 조희
  async getOrgUnitsList( param ) {
    return $nuxt.$axios.get( `${URL_ORGUNIT_LIST_ROOT}?type=ALL?orgUnitPath=/` );
  },

  //조직 트리 조희
  async getOrgUnitsTree( orgUnitPath = "/" ) {
    return $nuxt.$axios.get( `${URL_ORGUNIT_TREE}?orgUnitPath=${orgUnitPath}`, {timeout: 100000} );
  },

  //조직 트리 조희 (전체)
  async getOrgUnitsTreeALL() {
    return $nuxt.$axios.get( URL_ORGUNIT_TREE_ALL, {timeout: 100000} );
  },

  //학교 이름 목록
  async getSchoolNameList() {
    return $nuxt.$axios.get( URL_GET_SCHOOL_NAME_LIST, {timeout: 100000} );
  },

  //조직 추가/수정
  async insertOrgUnit( param = insertOrUpdateOrg_defaultParam ) {
    console.log( param );
    return $nuxt.$axios.post( URL_INSERT_OR_UPDATE_ORGUNIT, param, {timeout: 100000} );
  },

  //조직 수정
  async updateOrgUnit( param = insertOrUpdateOrg_defaultParam ) {
    console.log( param );
    return $nuxt.$axios.post( `${URL_UPDATE_ORGUNIT}`, param, {timeout: 100000} );
  },

  //권한 목록 => Tree
  async getPrivilegesList() {
    return $nuxt.$axios.get( URL_GET_PRIVILEGES_LIST, {timeout: 100000} );
  },

  //역할 목록
  async getRolesList() {
    return $nuxt.$axios.get( URL_GET_ROLE_LIST, {timeout: 100000} );
  },

  //할당된 역할 목록(roleId기준)
  async getRolesAssignmentsListByRoleId( roleId ) {
    return $nuxt.$axios.get( `${URL_GET_ROLE_ASSIGNMENTS_LIST_BY_ROLE_ID}/${roleId}`,
      {timeout: 100000} );
  },

  // 작업 수행자의 oid를 가져옵니다.
  getTaskUserOid( taskUserOid ) {

    return $nuxt.$validate.isNotEmpty( taskUserOid ) ? taskUserOid
      : $nuxt.$store.state.common.login.loginUser?.userOid;
  }

}

export default ( context, inject ) => {
  inject( "googleDirectory", googleDirectory );
};
