import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
import browserUtils from '~/plugins/core/utils/browserUtils';

/**
 * 사용자의 접속 정보를 수집하는 Util : userAgent와 userAgentData 두 곳에서 데이터를 가져옵니다.
 *
 * @property{function} getVisitInfo      - 접속한 유저 정보를 가져옵니다 : userAgentData가 있을 경우 userAgentData에서 데이터를 반환합니다.
 * */
const visitUtils = ( () => {

  const { isEmpty } = typeValidationUtils;
  const { getClientInfo } = browserUtils;

  return {


    /**
     * 사용자의 브라우저 , OS , 해상도 정보를 가져와 visitInfo에 맞게 변경하여 반환합니다.
     *
     * @return {Object} visitInfo
     * */
    async getUserVisitInfo() {

      const visitInfo = await getClientInfo();
      return this.parseVisitInfo( visitInfo );

    },


    /**
     * 사용자 방문 정보를 반환합니다.
     *
     * @param {Object} visitInfo - visitUtils.getVisitInfo에서 가져온 User 접속 정보
     * @return {Object} - 프레임워크 구조로 변환한 User 접속 정보
     * */
    parseVisitInfo( visitInfo ) {

      if ( isEmpty( visitInfo ) ) {
        return "";
      }

      const browserInfo = visitInfo.browserInfo;
      const osInfo = visitInfo.osInfo;
      const userResolution = visitInfo.resolutionInfo;

      if ( !browserInfo || !osInfo || !userResolution ) {
        return "";
      }

      // 1. 브라우저 정보 : name + major version
      const userBrowser = browserInfo.name;
      const userBrowserMajorVersion = browserInfo.version;
      const userBrowserFullVersion = browserInfo.fullVersion;

      // 2, OS Version
      const userOs = osInfo.name;
      const userOsVersion = osInfo.version;
      const userOsFlag = osInfo.flag;

      // 3 모바일 기기 여부
      const userDeviceTypeFlag = browserInfo.isMobile || browserInfo.isWebView ? "M" : "N";

      return {
        userBrowser,
        userBrowserMajorVersion,
        userBrowserFullVersion,
        userOs,
        userOsFlag,
        userOsVersion,
        userResolution,
        userDeviceTypeFlag
      }

    },

  }
})();


export default visitUtils;