//plugins/froala.js
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_editor.min.css";
import "froala-editor/css/froala_editor.css";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/third_party/embedly.min";
import "froala-editor/js/third_party/image_tui.min";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/table.min.js";

import Vue from "vue";
import VueFroala from "vue-froala-wysiwyg";

Vue.use( VueFroala );
