import portalSearchCommon from "~/plugins/portal/common/portalSearchCommon";
/**
 * 포탈 기관코드 공통 스크립트
 * @property {method} getOrgTypeCodeList            - 기관코드 관리 > 기관 구분 목록을 만들어줍니다. ( 검색영역 )
 * @property {method} getOrgAreaCodeList            - 기관코드 관리 > 지역 목록을 만들어줍니다. ( 검색영역 )
 * @property {method} getOrgControlCodeList         - 기관코드 관리 > 타겟 지역을 parent 로 갖는 관할 목록을 호출합니다.
 * @version 1.0 2021.12.28 by ted : PortalCommon 분리
 *  */
const portalOrgCommon = {
  /**
   * 기관코드 관리 > 기관 구분 목록을 만들어줍니다. ( 검색영역 )
   */
  async getOrgTypeCodeList() {

    let result = [];
    const URL_CODE_LIST = "/api/manager/v1/portalManagerCode_listAll";

    let param = {
      codeType : $nuxt.$constant.PORTAL_ORG_CODE_TYPE.ORG_TYPE.VALUE,
      orderBy : "CODE_KEY",
    };
    await $nuxt.$axios.post( URL_CODE_LIST, param ).then( async res => {

      if ( 200 === res.status && 0 < res.data.length ) {
        result = await portalSearchCommon.addLabelValue( res.data );
      }
    });
    return result;
  },

  /**
   * 기관코드 관리 > 지역 목록을 만들어줍니다. ( 검색영역 )
   */
  async getOrgAreaCodeList() {

    let result = [];
    const URL_CODE_LIST = "/api/manager/v1/portalManagerCode_listAll";

    let param = {
      codeType : $nuxt.$constant.PORTAL_ORG_CODE_TYPE.ORG_AREA.VALUE,
    };
    await $nuxt.$axios.post( URL_CODE_LIST, param ).then( async res => {

      if ( 200 === res.status && 0 < res.data.length ) {
        result = await portalSearchCommon.addLabelValue( res.data );
      }
    });

    return result;
  },

  /**
   기관코드 관리 > 선택한 지역의 관할 목록을 호출합니다.
   */
  async getOrgControlCodeList( codeKey ) {

    let result = [];
    const URL_CODE_LIST = "/api/manager/v1/portalManagerCode_listAll";

    let param = {
      codeType 		  : 'ORG_CONTROL',
      codeKey 		  : codeKey,
      allowLikeQuery  : true,
    };
    await $nuxt.$axios.post( URL_CODE_LIST, param ).then( async res => {

      if ( 200 === res.status && 0 < res.data.length ) {
        result = await portalSearchCommon.addLabelValue( res.data );
      }
    });
    return result;
  }
}

export default portalOrgCommon
