export const state = () => ( {
  receiverList : [],

  // 발송내역 > '텍스트 복사'시 저장할 메시지 내용
  messageContents : '',
} )

export const mutations = {
	SET_RECEIVER_LIST( state, receiverList ) {
		state.receiverList = receiverList;
	},

  SET_MESSAGE_CONTENTS( state, messageContents ) {
    state.messageContents = messageContents;
  }
}

export const actions = {
  setReceiverList( { commit }, payload ) {
    commit( "SET_RECEIVER_LIST", payload );
  },

  setMessageContents( { commit }, payload ) {
    commit( "SET_MESSAGE_CONTENTS", payload );
  }
}

export const getters = {
	getReceiverList( state ) {
		return state.receiverList;
	},

  getMessageContents( state ) {
    return state.messageContents;
  }
}
