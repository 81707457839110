// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Regular/NanumSquareRoundR.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Regular/NanumSquareRoundR.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Regular/NanumSquareRoundR.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Regular/NanumSquareRoundR.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./Bold/NanumSquareRoundB.eot");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./Bold/NanumSquareRoundB.woff2");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./Bold/NanumSquareRoundB.woff");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./Bold/NanumSquareRoundB.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./ExtraBold/NanumSquareRoundEB.eot");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./ExtraBold/NanumSquareRoundEB.woff2");
var ___CSS_LOADER_URL_IMPORT_10___ = require("./ExtraBold/NanumSquareRoundEB.woff");
var ___CSS_LOADER_URL_IMPORT_11___ = require("./ExtraBold/NanumSquareRoundEB.ttf");
var ___CSS_LOADER_URL_IMPORT_12___ = require("./Light/NanumSquareRoundL.eot");
var ___CSS_LOADER_URL_IMPORT_13___ = require("./Light/NanumSquareRoundL.woff2");
var ___CSS_LOADER_URL_IMPORT_14___ = require("./Light/NanumSquareRoundL.woff");
var ___CSS_LOADER_URL_IMPORT_15___ = require("./Light/NanumSquareRoundL.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_18___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
var ___CSS_LOADER_URL_REPLACEMENT_19___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"NanumSquareRound\";font-weight:400;font-style:normal;font-display:swap;src:local(\"NanumSquareRoundB\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\")}@font-face{font-family:\"NanumSquareRoundB\";font-weight:400;font-style:normal;font-display:swap;src:local(\"NanumSquareRoundB\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"truetype\")}@font-face{font-family:\"NanumSquareRoundEB\";font-weight:400;font-style:normal;font-display:swap;src:local(\"NanumSquareRoundEB\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_14___ + ") format(\"truetype\")}@font-face{font-family:\"NanumSquareRoundL\";font-weight:400;font-style:normal;font-display:swap;src:local(\"NanumSquareRoundL\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_15___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_16___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_17___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_18___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_19___ + ") format(\"truetype\")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
