/**
 * 포탈 검색 공통 스크립트
 * @property {method} addLabelValue      - 검색용 오브젝트로 이용하기 위해 label 과 value 를 추가합니다.
 * @property {method} addTotalSearch     - 검색용 목록에 '전체'값을 추가합니다.
 * @property {method} searchSystemList   - 검색에서 시스템 구분 리스트 필요시 사용
 * @version 1.0 2021.12.28 by ted : PortalCommon 분리
 * */
const portalSearchCommon = {

  // 검색용 오브젝트로 이용하기 위해 label 과 value 를 추가합니다.
  addLabelValue( list ) {

    if ( undefined === list || 0 === list.length ) {
      return;
    }

    list.forEach( item => {
      item.label = item.codeValue;
      item.value = item.codeKey;
    });

    return list;
  },
  // 검색용 목록에 '전체'값을 추가합니다.
  addTotalSearch( list ) {
    let totalObj = {
      label : '전체',
      value : '',
    };
    return [ totalObj, ...list ];
  },
  // 검색에서 시스템 구분 리스트 필요시 사용
  searchSystemList() {

    let adminMenuList = $nuxt.$store.getters[ "portal/menu/menuList" ]("adminMenu");

    if ( $nuxt.$validate.isEmpty( adminMenuList ) ) {
      return;
    }

    let systemList = adminMenuList[0].childTreeNodeList;

    let searchObjList = [];
    systemList.forEach( system => {

      let obj = {
        label : system.name,
        value : system.oid,
      }

      searchObjList.push( obj );
    } );

    return searchObjList;
  },


  /**
   * 검색 필터( TheSearchFilter 컴포넌트 ) 가 있는 모든 화면에서
   * 상세 또는 수정화면으로 이동하여 작업하다가 다시 목록으로 돌아온 경우에
   * 그 전의 검색 파라미터를 보존하고
   * 상세화면으로 이동하기 전과 동일하게 보여주도록 하는 것을 쉽게 하기 위해 추가합니다.
   *
   * list.vue -> TheSearchFilter 컴포넌트의 route 할당 발생 -> store 에 저장
   * -> list.vue fetch -> setQueryParam
   *
   * @author cheeeeze
   * @수정일 2021-11-16
   *
   * 날짜 정보에 따라 periodSearch 컴포넌트에 버튼 ( ex: 전체, 오늘, 당원, 올해 )값 세팅 추가
   *
   * @author woong
   * @최종수정일 2022-03-04
   */
  setQueryParam( searchFilterData, searchCnd ) {

    // console.log( "######### setQueryParam ..... ::: ", searchCnd );

    // 회원 상태 ( radio 선택값, string )
    if ( $nuxt.$validate.isNotEmpty( searchCnd.statusTypeFlag ) ) {
      let target = getSearchObjectByParamName('statusTypeFlag');
      target ? target.selectedCnd = searchCnd.statusTypeFlag : false;

    }


    // 검색어 ( input 입력 값, string )
    if ( $nuxt.$validate.isNotEmpty( searchCnd.searchQuery ) ) {

      let target = getSearchObjectByParamName('searchQuery');
      target ? target.selectedCnd.searchText = searchCnd.searchQuery : false;

    }

    // 검색 타입 선택 ( select 선택값, string )
    if ( $nuxt.$validate.isNotEmpty( searchCnd.searchTypeOptionsValue ) ) {

      let target = getSearchObjectByParamName('searchQuery');
      target ? target.selectedCnd.searchTypeOptionsValue = searchCnd.searchTypeOptionsValue : false;

    }

    // 날짜 검색 선택 값( Array )
    if ( $nuxt.$validate.isNotEmpty( searchCnd.searchDate ) ) {

      let searchRange = checkSearchDateRange( searchCnd.searchDate );

      let target = getSearchObjectByParamName('searchDate');

      if ( $nuxt.$validate.isNotEmpty( target ) ) {

        // 검색 기간 날짜 선택값 할당
        target.selectedCnd.searchDate = searchCnd.searchDate;

        if ( $nuxt.$validate.isNotEmpty( searchRange ) ) {

          // 검색 기간 radio 선택값 ( 오늘, 당월, 올해.. )
          target.selectedCnd.dateRadio = searchRange;
        }

        if ( $nuxt.$validate.isNotEmpty( searchCnd.refDate ) ) {

          // 기간 종류 선택 값
          target.selectedCnd.refDate = searchCnd.refDate;
        }
      }
      target ? target.selectedCnd.searchDate = searchCnd.searchDate : false;

    }

    // 학교(기관) 검색어
    if ( $nuxt.$validate.isNotEmpty( searchCnd.organizationName ) ) {

      let target = getSearchObjectByParamName('organizationName');
      target ? target.selectedCnd.searchText = searchCnd.organizationName : false;

    }

    // [ 관리자 계정만 보기 ] 체크박스를 할당한 경우에는 userTypeSet 에 'ADMIN' 하나만 담겨져서 넘어옴
    if ( $nuxt.$validate.isNotEmpty( searchCnd.userTypeSet ) &&
      1 === searchCnd.userTypeSet.length &&
      $nuxt.$constant.USER_TYPE.ADMIN.KEY === searchCnd.userTypeSet[ 0 ] ) {

      let target = getSearchObjectByParamName('showOnlyAdmin');
      target ? target.selectedCnd = [ "admin" ] : false;
    }

    /**
     * searchFilter 중 paramName 일치하는 오브젝트를 반환합니다.
     */
    function getSearchObjectByParamName( cnd ) {
      return searchFilterData.find( item => item.paramName === cnd );
    }

    /**
     * searchDate의 범위를 검사합니다.
     *
     * @param searchDate
     */
    function checkSearchDateRange( searchDate ) {

      const TODAY = "TODAY";
      const MONTH = "THIS_MONTH";
      const YEAR = "THIS_YEAR";

      let today = new Date();

      let result = "";

      let firstDateOfThisMonth = $nuxt.$util.dateUtils.parseDate( new Date( today.getFullYear(), today.getMonth(), 1 ) );
      let lastDateOfThisMonth = $nuxt.$util.dateUtils.parseDate( new Date( today.getFullYear(), today.getMonth() + 1, 0 ) );
      let firstDateOfThisYear = $nuxt.$util.dateUtils.parseDate( new Date( today.setFullYear( today.getFullYear(), 0, 1 ) ) );
      let lastDateOfThisYear = $nuxt.$util.dateUtils.parseDate( new Date( today.getFullYear(), 11, 31 ) );

      if ( firstDateOfThisMonth === searchDate[0] && lastDateOfThisMonth === searchDate[1] ) {
        result = MONTH;
      }

      if ( firstDateOfThisYear === searchDate[0] && lastDateOfThisYear === searchDate[1] ) {
        result = YEAR;
      }

      if ( today === searchDate[0] && today === searchDate[1] ) {
        result = TODAY;
      }

      return result;

    }

    return searchFilterData;
  },
}

export default portalSearchCommon;
